import { f7 } from 'framework7-react';
import { FC, useMemo, useState } from 'react';
import ContactImageAvatar from 'src/assets/images/repair-contact-image.jpeg';
import style from './CarRepairDetailPageStyle.module.css';
import { LocationBadge } from './LocationBadge';
import HelpIcon from '@/assets/images/reservation-status/help.svg';
import MessageIcon from '@/assets/images/reservation-status/message.svg';
import StatusInspect from '@/assets/images/reservation-status/status0.svg';
import StatusAccepted from '@/assets/images/reservation-status/status1.svg';
import StatusCompleted from '@/assets/images/reservation-status/status10.svg';
import StatusResponseCompleted from '@/assets/images/reservation-status/status11.svg';
import StatusRoadServiceInArranged from '@/assets/images/reservation-status/status2.svg';
import StatusTransportationCompleted from '@/assets/images/reservation-status/status3.svg';
import StatusFactoryArrivalCompleted from '@/assets/images/reservation-status/status4.svg';
import StatusInspectionUnderway from '@/assets/images/reservation-status/status5.svg';
import StatusToBeCommence from '@/assets/images/reservation-status/status7.svg';
import StatusPartsInArranged from '@/assets/images/reservation-status/status8.svg';
import StatusInProgress from '@/assets/images/reservation-status/status9.svg';
import { PopupConfirm } from '@/components/projects/CarRepair/PopupConfirm';
import {
  PopupTemplate,
  PopupTemplateProps,
} from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { repairStatus } from '@/consts/reservation';
import { MAffiliateSettingsApiResponse } from '@/types/api/infoApi';
import { RepairInfo } from '@/types/api/reservationApi';

interface RepairStatusBlockProps {
  data?: RepairInfo;
  affiliateData?: MAffiliateSettingsApiResponse['data'];
}

export const RepairStatusBlock: FC<RepairStatusBlockProps> = ({
  data,
  affiliateData,
}) => {
  const [isHelpPopupOpened, setIsHelpPopupOpened] = useState(false);
  const [isContactPopupOpened, setIsContactPopupOpened] = useState(false);
  const [isAcceptedPopupOpened, setIsAcceptedPopupOpened] = useState(false);
  const image = useMemo(() => {
    if (data === undefined) {
      return '';
    }

    switch (data.status) {
      case repairStatus.ACCEPTED:
        return StatusAccepted;
      case repairStatus.ROAD_SERVICE_IN_ARRANGED:
        return StatusRoadServiceInArranged;
      case repairStatus.TRANSPORTATION_COMPLETED:
        return StatusTransportationCompleted;
      case repairStatus.FACTORY_ARRIVAL_COMPLETED:
        return StatusFactoryArrivalCompleted;
      case repairStatus.INSPECTION_UNDERWAY:
        return StatusInspectionUnderway;
      case repairStatus.REPAIR_REQUEST_COMPLETED:
        return StatusInProgress;
      case repairStatus.COMPLETED:
        return StatusCompleted;
      case repairStatus.RESPONSE_COMPLETED:
        return StatusResponseCompleted;
      default:
        return StatusInProgress;
    }
  }, [data]);

  return (
    <div className={`${style['block']} ${style['block-doc']}`}>
      <img src={image} />
      <div className={style['title']}>
        <span className={style['padding-title']}>{data?.status}</span>
        <img src={HelpIcon} onClick={() => setIsHelpPopupOpened(true)} />
      </div>
      <div className={style['w-full']}>
        <p className={style['text']}>{data?.guidance}</p>
        <p className={style['text']}>{data?.action}</p>
        {data?.status === repairStatus.ACCEPTED ? (
          <div className={style['w-full']}>
            <div className={style['outdate-update-notice']}>
              <p className={style['outdate-update-title']}>
                修理依頼時に選択した車の入庫先
              </p>
              <div className={style['car-location']}>
                <LocationBadge value={'車を購入した販売店'} />
                <LocationBadge value={'どちらでもいい'} />
                <p className={style['text-wait-response']}>
                  サポートセンターからの連絡をお待ちください
                </p>
              </div>
              <div className={style['procedure']}>
                <LocationBadge value={'近隣の工場など'} />
                <p
                  className={style['text-procedure']}
                  onClick={() => setIsAcceptedPopupOpened(true)}
                >
                  近隣の工場などに入庫する場合の手順
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={style['message-area']}
            onClick={() => setIsContactPopupOpened(true)}
          >
            <img src={MessageIcon} />
            <p>サポートセンター</p>
            <i className="icon-right" />
          </div>
        )}
      </div>
      <HelpPopup
        opened={isHelpPopupOpened}
        handleClose={() => setIsHelpPopupOpened(false)}
      />
      <PopupConfirm
        opened={isAcceptedPopupOpened}
        onPopupClose={() => setIsAcceptedPopupOpened(false)}
        callCenterTel={
          data?.nyukosaki_phone_num || affiliateData?.data?.callcenter_tel
        }
      />
      {affiliateData?.data && (
        <ContactPopup
          opened={isContactPopupOpened}
          handleClose={() => setIsContactPopupOpened(false)}
          data={affiliateData.data}
        />
      )}
    </div>
  );
};

const HelpList = [
  {
    img: StatusAccepted,
    title: '修理受付',
    content: 'アプリまたは店頭にて修理受付をします。',
  },
  {
    img: StatusTransportationCompleted,
    title: '工場入庫',
    content:
      '工場にお車を入庫いただきます。（ロードサービスをご利用の場合、受付完了後に担当者がそちらに伺いお車を搬送いたします。）',
  },
  {
    img: StatusInspect,
    title: 'お見積もり・審査',
    content:
      '入庫後、点検しお見積りを作成、お見積り内容をもとに審査を行います。',
  },
  {
    img: StatusPartsInArranged,
    title: '修理準備',
    content: '審査結果を工場に伝え、修理の準備および部品の手配をします。',
  },
  {
    img: StatusToBeCommence,
    title: '修理',
    content: '準備が完了しましたら、お車の修理作業を行います。',
  },
  {
    img: StatusCompleted,
    title: '修理完了・納車',
    content:
      '作業が完了しましたら、工場からご報告の上修理済みのお車を納車いたします。',
  },
];

const HelpPopup: FC<Partial<PopupTemplateProps>> = ({
  opened = false,
  handleClose,
}) => (
  <PopupTemplate
    opened={opened}
    title="修理受付後の流れ"
    onPopupClose={handleClose}
    handleClose={handleClose}
  >
    <div className={style['help-container']}>
      {HelpList.map((item, index) => (
        <div className={style['help-row']} key={item.title}>
          <div className={style['help-image-container']}>
            <span>{index + 1}</span>
            <div>
              <img src={item.img} width={48} />
            </div>
          </div>
          <div className={style['help-content']}>
            <h4>{item.title}</h4>
            <p>{item.content}</p>
          </div>
        </div>
      ))}
      <div className={style['help-note']}>
        <h4>お支払いについて</h4>
        <p>
          お客様のご負担分が発生する場合のお支払いにつきましては、サポートセンターもしくは入庫先よりご案内いたします。
        </p>
      </div>
    </div>
  </PopupTemplate>
);

export const ContactPopup: FC<
  Partial<PopupTemplateProps> & {
    data: MAffiliateSettingsApiResponse['data']['data'];
  }
> = ({ data, opened = false, handleClose }) => {
  const handleCallSupportCenter = () => {
    if (data.callcenter_tel) {
      window.location.href = 'tel://' + data.callcenter_tel;
    } else {
      openAlertNoWarranty();
    }
  };

  const openAlertNoWarranty = () => {
    f7.dialog.alert(
      '修理保証に未加入ですのでご利用可能なサービスがございません',
      ' エラー',
      () => {
        f7.dialog.close();
      },
    );
  };

  return (
    <PopupTemplate
      opened={opened}
      title="サポートセンター"
      onPopupClose={handleClose}
      handleClose={handleClose}
    >
      <div className={style['contact-container']}>
        <p>サポートセンターに電話でご相談</p>
        <div
          className={style['contact-box']}
          onClick={() => handleCallSupportCenter()}
        >
          <img className={style['contact-avatar']} src={ContactImageAvatar} />
          <div className={style['contact-info-text']}>
            {data.callcenter_name}
          </div>
          <div className={style['contact-call-button']}>
            <i className="icon-telephone" />
          </div>
        </div>
      </div>
    </PopupTemplate>
  );
};
