import { Link, Page } from 'framework7-react';
import { Router } from 'framework7/types';
import parse from 'html-react-parser';
import { FC, useState } from 'react';
import styles from './UpdateWarrantyStep3Page.module.css';
import { TextRead } from '@/components/uiParts/TextRead/TextRead';
import { store } from '@/config/store';
import { termType } from '@/consts/terms';
import { useTerm } from '@/hooks/api/term/useTerm';
import { findAppendWarrantyOfTypeOne } from '@/utils/warranty';
import './UpdateWarrantyStep3.scss';
interface Props {
  setStep: (n: number) => void;
  f7router: Router.Router;
}
export const UpdateWarrantyStep3Page: FC<Props> = ({ setStep, f7router }) => {
  //Todo: 保証規約取得

  //ロードサービスの規約手直
  const { data: roadServiceTerms } = useTerm(termType.ROAD_SERVICE);
  const roadServiceTerm = roadServiceTerms?.term;

  const { warranties } = store.state.carItemInfo;
  const warrantyTerms =
    findAppendWarrantyOfTypeOne(warranties[0])?.low_ver || '';

  const warranty = store.state.updateWarrantyInfo;
  const warrantyTermTitle = warranty.warranty_name + '保証規約';
  const roadServiceTermTitle = warranty.warranty_name + 'ロード保証規約';

  const [acceptFlg, setAcceptFlg] = useState(false);

  const execEmit = (n: number) => {
    setStep(n);
  };

  const handleNavigateClick = () => {
    return;
  };

  return (
    <Page name="UpdateWarrantyStep3">
      <div className="top">
        <Link animate back>
          <i className="icon-close" />
        </Link>
        <div className="u-text-center u-font-size-xl u-font-bold">
          プランの変更
        </div>
        <i />
      </div>
      <table className="menu-table u-margin-bottom-lg">
        <tr className="menu-tr-1">
          <td>●</td>
          <td>●</td>
          <td className="select-number">
            <div>3</div>
          </td>
          <td>●</td>
          <td>●</td>
          <td>●</td>
        </tr>
        <tr>
          <td className="menu-table-item">プラン選択</td>
          <td className="menu-table-item">
            契約情報
            <br />
            確認
          </td>
          <td
            style={{ verticalAlign: 'top' }}
            className="menu-falling-under menu-table-item"
          >
            重要事項
            <br />
            確認
          </td>
          <td className="menu-table-item">
            支払回数
            <br />
            選択
          </td>
          <td className="menu-table-item">
            決済方法
            <br />
            選択
          </td>
          <td style={{ verticalAlign: 'top' }} className="menu-table-item">
            最終確認
          </td>
        </tr>
      </table>
      <div className={styles['container']}>
        <div className={`${styles['step-title']} u-margin-bottom-sm`}>
          重要事項のご確認
        </div>
        <div className="u-margin-bottom-sm">
          以下の事項をご確認いただき、同意の上手続きを進めてください。
        </div>

        <div className={`${styles.item} u-margin-bottom-sm`}>
          <TextRead
            title={warrantyTermTitle}
            f7router={f7router}
            path=""
            backgroundColor="grey"
          >
            {parse(warrantyTerms)}
          </TextRead>
        </div>
        <div
          className={`${styles.item} u-margin-bottom-sm`}
          style={{ marginTop: '30px' }}
        >
          <TextRead
            title={roadServiceTermTitle}
            f7router={f7router}
            path=""
            backgroundColor="grey"
          >
            {parse(roadServiceTerm ?? '')}
          </TextRead>
        </div>

        <label className={styles['ECM_CheckboxInput']}>
          <input
            className={styles['ECM_CheckboxInput-Input']}
            type="checkbox"
            checked={acceptFlg}
            onChange={() => setAcceptFlg(!acceptFlg)}
          />
          <span className={styles['ECM_CheckboxInput-DummyInput']} />
          <span className={styles['ECM_CheckboxInput-LabelText']}>
            上記2点の規約に同意する
          </span>
        </label>
        <div style={{ width: '100%', textAlign: 'center' }}>
          {acceptFlg ? (
            <button className={styles.btn} onClick={() => execEmit(4)}>
              次へ
            </button>
          ) : (
            <button className={styles['inactive-btn']}>次へ</button>
          )}
        </div>
      </div>
    </Page>
  );
};
