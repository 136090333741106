import { Checkbox as F7Checkbox, f7, useStore } from 'framework7-react';
import { ChangeEvent, FC, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { Radio } from '../Inputs/Radio';
import { TextInput } from '../Inputs/TextInput';
import style from './CarWashHandStep1Style.module.css';
import CarWashHandPromote1 from '@/assets/images/carwash/carwash_hand_promote_1.png';
import { ReactComponent as ReportIcon } from '@/assets/images/icon-report.svg';
import { ReactComponent as IconCarWashCard } from '@/assets/images/menu/icon_car-wash-card.svg';
import { ReactComponent as IconCarWashHandCard } from '@/assets/images/menu/icon_car-wash-hand-card.svg';
import { Button } from '@/components/uiParts/Button';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { carSizes, carSizesOptions } from '@/consts/carWash';
import { url } from '@/consts/url';
import {
  Step1FormData,
  useCarWashHandStep1,
} from '@/hooks/api/carWash/useCarWashHandStep1';
import { usePromote } from '@/hooks/usePromote';
import { Car } from '@/types/api/customerApi';
import { CourseItem, OptionItem } from '@/types/api/reservationApi';
import { isDelivered } from '@/utils/car';
import { getCarPhoto } from '@/utils/utils';
interface Props {
  courseItems: CourseItem[];
  optionItems: OptionItem[];
  step1Data: Step1FormData;
  onSubmit: (data: Step1FormData) => void;
  onClickCancel: () => void;
}

export const CarWashHandStep1: FC<Props> = ({
  courseItems,
  optionItems,
  step1Data,
  onSubmit,
  onClickCancel,
}) => {
  const carsInfo = useStore(store, 'carsInfo') as Required<Car>[];
  const { fee, prepaid, paymentShort, formMethods } = useCarWashHandStep1(
    step1Data,
    courseItems,
    optionItems,
  );
  const {
    watch,
    handleSubmit,
    setValue,
    trigger,
    formState: { isValid, errors },
  } = formMethods;
  const useUserCar = watch('car.unregisteredCar.useUserCar');
  const tStockCarId = watch('car.tStockCarId');
  const carwashItemCode = watch('car.carwashItemCode');
  const optionItemCodes = watch('car.optionItemCodes');
  const carSize = watch('car.size');
  const openSizeSelect = watch('car.openSizeSelect');
  const { isShowPromote } = usePromote();

  const submit = (data: Step1FormData) => {
    onSubmit(data);
  };
  const validOptions = optionItems.filter(
    (item) => item.car_size === carSize || item.car_size === carSizes.UNKNOWN,
  );
  const validCars = useMemo(() => {
    return carsInfo.filter(
      (car) => !!car.t_stock_car_id && isDelivered(car.delivery_complete_flg),
    );
  }, [carsInfo]);

  useEffect(() => {
    if (validCars.length === 0) {
      setValue('car.unregisteredCar.useUserCar', false);
    }
  }, [setValue, validCars]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(submit)} className={style['container']}>
        <div className={style['heading-card']}>
          <IconCarWashCard className={style['heading-card-image']} />
          <IconCarWashHandCard className={style['heading-card-image']} />
          <h4>出張水なし手洗い洗車とは？</h4>
          <p>
            水を使わない「水なし洗車」で、いつでもどこでもあなたの車を洗車します。
          </p>
          <button
            type="button"
            onClick={() =>
              f7.views.main.router.navigate(paths.carWashHandAbout, {
                props: {
                  showLink: false,
                },
              })
            }
          >
            詳しくはこちら
            <i className="icon-right" />
          </button>
        </div>
        <div className={style['input-group']}>
          <h2 className={style['input-label']}>
            施工する車の情報を入力してください
            <span
              className={`${style['input-label-tips']} ${style['required']}`}
            >
              必須
            </span>
          </h2>
          {validCars.length > 0 && (
            <Radio
              id="user-car"
              name="car.unregisteredCar.useUserCar"
              checked={useUserCar}
              value={true}
              className={style['input-radio']}
            >
              既に登録されている車から選ぶ
            </Radio>
          )}
          <Radio
            id="input-car"
            name="car.unregisteredCar.useUserCar"
            checked={!useUserCar}
            value={false}
            className={style['input-radio']}
          >
            新しく車を登録する
          </Radio>
        </div>
        {/* 既に登録されている車から選ぶ */}
        {useUserCar && (
          <div>
            {validCars.map((car, index) => (
              <div
                key={index}
                className={`${style['input-radio']} ${
                  style['input-radio-box']
                } ${
                  tStockCarId === car.t_stock_car_id
                    ? style['input-radio-car-active']
                    : ''
                }`}
                onClick={() => {
                  setValue('car.tStockCarId', car.t_stock_car_id);
                  trigger('car.tStockCarId');
                }}
              >
                <div className={style['input-car-image']}>
                  <img src={getCarPhoto(car)} />
                </div>
                <div>
                  <h4>
                    {car.maker_nm}
                    {car.car_nm}
                  </h4>
                  <p>車台番号：{car.vin}</p>
                  <p>型式：{car.katasiki}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        {/* 新しく車を登録する */}
        {!useUserCar && (
          <>
            <div>
              <TextInput
                label="メーカー"
                name="car.unregisteredCar.makerName"
                error={errors?.car?.unregisteredCar?.makerName?.message}
                required
              />
              <TextInput
                label="車種"
                name="car.unregisteredCar.carName"
                error={errors?.car?.unregisteredCar?.carName?.message}
                required
              />
              <TextInput
                label="車の色"
                name="car.unregisteredCar.carColor"
                error={errors?.car?.unregisteredCar?.carColor?.message}
                required
              />
              <TextInput
                label="車台番号"
                name="car.unregisteredCar.carVin"
                error={errors?.car?.unregisteredCar?.carVin?.message}
                required
              />
              <TextInput
                label="型式"
                name="car.unregisteredCar.modelCode"
                error={errors?.car?.unregisteredCar?.modelCode?.message}
                required
              />
            </div>
            <div
              className={`${style['input-group']} ${style['has-border-top']}`}
            >
              <h2 className={style['input-label']}>
                車両ナンバーを入力してください。
              </h2>
              <TextInput
                label="地域名"
                name="car.unregisteredCar.transportName"
                error={errors?.car?.unregisteredCar?.transportName?.message}
                required
              />
              <TextInput
                label="分類番号"
                name="car.unregisteredCar.classNo"
                error={errors?.car?.unregisteredCar?.classNo?.message}
                required
              />
              <TextInput
                label="平仮名等"
                name="car.unregisteredCar.hiragana"
                error={errors?.car?.unregisteredCar?.hiragana?.message}
                required
              />
              <TextInput
                label="車両番号"
                name="car.unregisteredCar.registryNo"
                error={errors?.car?.unregisteredCar?.registryNo?.message}
                required
              />
            </div>
          </>
        )}
        {openSizeSelect && (
          <div className={`${style['input-group']} ${style['has-border-top']}`}>
            <h2 className={style['input-label']}>
              車のサイズを選択してください
              <span
                className={`${style['input-label-tips']} ${style['required']}`}
              >
                必須
              </span>
              <p className={style['input-label-sub']}>
                車のサイズによって料金が変わります。
              </p>
            </h2>
            {carSizesOptions.map((item, index) => (
              <Radio
                id={`size-${item.value}`}
                name="car.size"
                key={index}
                value={item.value}
                checked={carSize === item.value}
                className={style['input-radio']}
              >
                {item.label}
              </Radio>
            ))}
            <p
              className={style['input-radio-link']}
              onClick={() =>
                window.open(`${url.MAIKURU_BASE_URL}/files/car_size.pdf`)
              }
            >
              <ReportIcon fontSize={24} />
              車種別のサイズ表はこちら
            </p>
            <p className={style['input-radio-info']}>
              <span>※</span>
              <span>
                車両サイズと洗車メニューのサイズが誤っていた場合は、後日差額を清算させていただきます。
              </span>
            </p>
          </div>
        )}
        <div className={style['promote-box']}>
          {isShowPromote && <img src={CarWashHandPromote1} />}
        </div>
        {!!carSize && (
          <div
            className={`${style['input-group']} ${
              isShowPromote ? '' : style['has-border-top']
            }`}
          >
            <h2 className={style['input-label']}>
              ご希望のコースを選択してください
              <span
                className={`${style['input-label-tips']} ${style['required']}`}
              >
                必須
              </span>
            </h2>
            {courseItems
              .filter(
                (item) =>
                  item.car_size === carSize ||
                  item.car_size === carSizes.UNKNOWN,
              )
              .map((item, index) => (
                <Radio
                  id={`course-${item.code}`}
                  name="car.carwashItemCode"
                  key={index}
                  value={item.code}
                  checked={carwashItemCode === item.code}
                  className={`${style['input-radio']} ${style['input-radio-box']}`}
                >
                  <div>
                    <h4>{item.name}</h4>
                    <p>{item.price.toLocaleString()}円(税込)</p>
                    <p className={style['sub-text']}>{item.details}</p>
                  </div>
                </Radio>
              ))}
          </div>
        )}
        <div className={style['price-box']}>
          <p className={style['price-box-label']}>現在の合計金額</p>
          <p className={style['price-box-price']}>{fee.toLocaleString()}</p>
          <p className={style['price-box-currency']}>円</p>
        </div>
        {!!paymentShort && (
          <div className={style['price-box-warning']}>
            現在{paymentShort.toLocaleString()}円不足しています
          </div>
        )}
        <div className={style['price-box-info']}>
          ご登録された{prepaid?.name}の残高から自動でお支払いが行われます。
        </div>
        {!!validOptions.length && !!carSize && (
          <div className={`${style['input-group']}`}>
            <h2 className={style['input-label']}>
              オプションを選択してください
              <span className={`${style['input-label-tips']}`}>任意</span>
              <p className={style['input-label-sub']}>別途料金がかかります。</p>
            </h2>
            {validOptions.map((item, index) => (
              <label
                key={item.code}
                className={`${style['input-checkbox']} ${style['input-radio']} ${style['input-radio-box']}`}
              >
                <F7Checkbox
                  id={`option-${item.code}`}
                  checked={optionItemCodes.includes(item.code)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      setValue('car.optionItemCodes', [
                        ...optionItemCodes,
                        item.code,
                      ]);
                    } else {
                      setValue('car.optionItemCodes', [
                        ...optionItemCodes.filter((i) => i !== item.code),
                      ]);
                    }
                  }}
                />
                <div>
                  <h4>{item.name}</h4>
                  <p>{item.price.toLocaleString()}円(税込)</p>
                </div>
              </label>
            ))}
          </div>
        )}
        <div className={style['button-group']}>
          <Button text="次へ" type="submit" disabled={!isValid} />
          <Button
            style={{
              color: '#232323',
              backgroundColor: '#fff',
              marginTop: '8px',
            }}
            text="キャンセル"
            onClick={onClickCancel}
          />
        </div>
      </form>
    </FormProvider>
  );
};
