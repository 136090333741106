import { f7 } from 'framework7-react';
import { FC } from 'react';
import style from './SettingsItemCardStyle.module.css';
import { drivelogSendFlg } from '@/consts/linkDrive';

type Status = 'notification' | 'no-notification' | 'normal';

interface OptionType {
  optionsTitle: string;
  props?: number;
  status?: Status;
  statusText?: string;
  detailInfo?: string;
}

interface InfoType {
  title: string;
  path: string;
  text: string;
}

interface Props {
  info: InfoType;
  options: OptionType[];
}

export const SettingsItemCard: FC<Props> = ({ info, options }) => {
  const handleClickNavigation = (path: string) => {
    f7.view.main.router.navigate(path, { animate: true });
  };

  return (
    <div className={style.card}>
      <div className={style.head}>
        <h3 className={style.heading3}>{info.title}</h3>
        <div
          className={style['change-button']}
          onClick={() => handleClickNavigation(info.path)}
        >
          変更する
        </div>
      </div>
      <div className={style.description}>{info.text}</div>
      {options.map((item, index) => (
        <div className={style.list} key={index}>
          <div className={style['list-label']}>{item.optionsTitle}</div>
          <div
            className={`${style['list-content']} ${
              style[
                `${
                  item.props === drivelogSendFlg.NOTIFY_ON ? 'notification' : ''
                }`
              ]
            }`}
          >
            {item.statusText ? item.statusText : item.detailInfo}
          </div>
        </div>
      ))}
    </div>
  );
};
