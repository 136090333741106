import { FC, useMemo } from 'react';
import style from './RepairResultStyle.module.scss';

type CommonTicketPartsProps = {
  header: string;
  listPart: {
    desc: string;
    title: string;
    ticketList?: {
      label: string;
      value: number;
      isPercentage?: boolean;
    }[];
  }[];
};

export const CommonTicketParts: FC<CommonTicketPartsProps> = ({
  header = '',
  listPart,
}) => {
  const isNotEmptyList = listPart?.find(({ ticketList }) => ticketList?.length);
  return (
    <div className={style['common-part']}>
      <div className={style['header']}>{header}</div>
      {isNotEmptyList ? (
        listPart?.map(({ desc, title, ticketList }, idx) => (
          <div key={`${desc}-${idx}`} className={style['block-container']}>
            <div className={style['desc']}>{desc}</div>
            <div className={style['block-background']}>
              <div className={style['block-ticket']}>
                <div className={style['title']}>{title}</div>
                <div>
                  {ticketList?.map(({ label, value, isPercentage }, idx) => (
                    <div key={`${label}-${idx}`} className={style['content']}>
                      <div>{label}</div>
                      <div className={style['amount']}>
                        {value.toLocaleString()}
                        {isPercentage ? '%' : '円'}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>お支払いは不要です。</div>
      )}
    </div>
  );
};
