import { FC } from 'react';
import { DisplayWarrantyItem } from '../WarrantyManagementHelper';
import styles from './WarrantyUnderChangingStyle.module.css';
import { InfoCard } from '@/components/uiParts/InfoCard/InfoCard';
import { List } from '@/components/uiParts/List/List';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';

interface WarrantyUnderChangingProps {
  warrantyNo: string;
  warranty: DisplayWarrantyItem;
  handleClickOpenUpdate: () => void;
}

export const WarrantyUnderChanging: FC<WarrantyUnderChangingProps> = ({
  warranty,
  handleClickOpenUpdate,
}) => {
  const warrantyList = [
    {
      label: '保証開始日',
      value: warranty.warrantyStartDate,
    },
    { label: '保証終了日', value: warranty.warrantyEndDate },
  ];
  return (
    <RoundSheet>
      <div className={styles.card}>
        <div className={styles['card-head']}>
          <h3>{warranty.warrantyName}</h3>
          <a onClick={handleClickOpenUpdate} className={styles.link}>
            <h3 className={styles.inline}>詳しくみる</h3>
          </a>
          <i className="icon-right" />
        </div>
        <br />
        <div>
          <InfoCard
            bgColor="warning"
            text="適用開始前の保証です"
            textSub={`変更適用日：${warranty.warrantyStartDate}`}
            iconType="confirm-notification"
          />
          <List
            labelWidth="150px"
            list={warrantyList}
            listClass={styles['list-class']}
          />
        </div>
      </div>
    </RoundSheet>
  );
};
