import { AccordionContent, List, ListItem, f7 } from 'framework7-react';
import { FC, useEffect } from 'react';
import style from './CarRepairDetailPageStyle.module.css';
import { RepairDetail } from './RepairDetail/RepairDetail';
import { RepairResult } from './RepairResult/RepairResult';
import { RepairStatusBlock } from './RepairStatusBlock';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { caller, repairStatus } from '@/consts/reservation';
import { useFetchCarRepairInspection } from '@/hooks/api/carRepair/useFetchCarRepairInspection';
import './CarRepairDetailPageStyle.scss';
import { useMAffiliateSettings } from '@/hooks/api/info/useFetchMAffiliateSettings';

type CarRepairDetailPageProps = {
  id: string;
  fromPage?: string;
};

export const CarRepairDetailPage: FC<CarRepairDetailPageProps> = ({
  id,
  fromPage,
}) => {
  const { firstItem, error } = useFetchCarRepairInspection({
    m_customer_id: store.state.authInfo.m_customer_id,
    t_repair_id: Number(id),
    call_kbn: caller.MAIKURU,
  });
  const { data: affiliateData } = useMAffiliateSettings({
    m_affiliate_id: store.state.customerInfo.m_affiliate_id,
  });

  const handleClickBack = () => {
    f7.view.main.router.navigate(fromPage || paths.reservationHistory, {
      transition: 'push-reverse',
    });
  };

  useEffect(() => {
    if (error?.message) {
      f7.dialog.alert(
        '',
        '詳細情報を取得できませんでした。\nお手数ですがサポートセンターにお問い合わせください。',
        () => {
          handleClickBack();
        },
      );
    }
  }, [error]);

  return (
    <PageTemplate
      showNavBack={true}
      pageName="CarRepairDetail"
      title="作業詳細"
      handleClickBack={handleClickBack}
    >
      <RepairStatusBlock data={firstItem} affiliateData={affiliateData} />
      {firstItem?.status === repairStatus.REPAIR_REQUEST_COMPLETED ? (
        <div className={style['repair-container']}>
          <RepairResult data={firstItem} affiliateData={affiliateData} />
          <List>
            <ListItem accordionItem accordionItemOpened title="依頼内容">
              <AccordionContent>
                <RepairDetail firstItem={firstItem} />
              </AccordionContent>
            </ListItem>
          </List>
        </div>
      ) : (
        <RepairDetail firstItem={firstItem} />
      )}
    </PageTemplate>
  );
};
