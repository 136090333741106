import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { CarInfoTiredToTheWarranty } from '../../pages/WarrantyManagement/WarrantyManagementHelper';
import style from './SettlementInformationStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';
import { CustomList } from '@/components/uiParts/List/CustomList';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import {
  OUTPUT_FLAG,
  historyType,
  invalidateFlag,
  noPayFlg,
  paymentTypeId,
} from '@/consts/payment';
import {
  BillInformation,
  BillInformationDetail,
  PaymentMethod,
} from '@/types/api/customerApi';
import { PaymentTypeId } from '@/types/payment';
import {
  formatDateISO,
  formatToJaDate,
  formatYearMonthToJa,
  hyphenToDayNoPadding,
} from '@/utils/date';
import {
  isBankOrPayEasy,
  switchPaymentIcon,
  switchPaymentMethodDesc,
} from '@/utils/payment';

interface SettlementInformationProps {
  carInfo: CarInfoTiredToTheWarranty;
  handleClickChangeSettlement: () => void;
  f7router: Router.Router;
  displayPayment?:
    | (BillInformationDetail & {
        register?: string;
      })
    | Required<PaymentMethod>;
}

export const SettlementInformation: FC<SettlementInformationProps> = ({
  carInfo,
  handleClickChangeSettlement,
  f7router,
  displayPayment,
}) => {
  const { warranty } = carInfo;
  const bills = useStore(store, 'getBillInfo') as Required<BillInformation>[];
  const warrantyBill = bills.find(
    (bill) => bill.warranty_no === warranty.warrantyNo,
  );
  const untreatedBillDetails = (
    warrantyBill?.bill_infomation_details || []
  ).filter(
    (bid) =>
      bid.no_pay_flg === noPayFlg.UNTREATED &&
      bid.invalidate_flg == invalidateFlag.NOT_EXPIRED,
  );

  const showNextBill =
    untreatedBillDetails.length != 0 &&
    (carInfo.billInfo.currentBillInfo?.no_pay_flg !==
      noPayFlg.PAYMENT_CONFIRMED ||
      carInfo.billInfo.currentBillInfo?.m_payment_type_id !==
        paymentTypeId.CASH);

  return (
    <RoundSheet>
      <div className={style.head}>
        <h3 className={style.heading3}>決済情報</h3>
        <Button
          className={style.button}
          onClick={handleClickChangeSettlement}
          colorTheme="gray"
        >
          {`決済方法を${!displayPayment?.m_payment_type_id ? '追加' : '変更'}`}
        </Button>
      </div>
      {displayPayment && (
        <div className={style['list-wrap']}>
          <ul className={style.list}>
            <li className={style['list-item']}>
              <div className={style['icon-wrap']}>
                <i
                  className={switchPaymentIcon(
                    displayPayment.m_payment_type_id as PaymentTypeId,
                  )}
                />
              </div>
              <div>
                <p className={style['bank-description']}>
                  {switchPaymentMethodDesc(
                    displayPayment?.m_payment_type_id ?? 0,
                    displayPayment.bank_name,
                  )}
                </p>
                {isBankOrPayEasy(displayPayment?.m_payment_type_id)
                  ? displayPayment?.account_number && (
                      <span className={style['account-info']}>
                        {`口座番号：${displayPayment?.account_number}`}
                      </span>
                    )
                  : displayPayment?.card_no && (
                      <span className={style['account-info']}>
                        {`カード番号：${displayPayment?.card_no}`}
                      </span>
                    )}
                <span className={style['account-info']}>
                  {`登録日：${formatToJaDate(
                    formatDateISO(
                      displayPayment?.register || new Date().toISOString(),
                    ),
                  )}`}
                </span>
              </div>
            </li>
            {!!carInfo.billInfo.prevBillInfo &&
              carInfo.billInfo.prevBillInfo?.no_pay_flg ===
                noPayFlg.BEFORE_PAYMENT &&
              (carInfo.billInfo.prevBillInfo.output_flg ===
                OUTPUT_FLAG.invoice ||
                carInfo.billInfo.prevBillInfo.output_flg ===
                  OUTPUT_FLAG.noSettlement) && (
                <li className={style['danger-card']}>
                  <IconCard
                    isTransition
                    onClick={() => {
                      f7.view.main.router.navigate({
                        // name of paths.paymentsHistory(warranty?.warrantyNo)
                        name: '支払履歴',
                        params: {
                          warranty_no: warranty?.warrantyNo,
                        },
                        query: {
                          historyType: historyType.WARRANTY,
                        },
                      });
                    }}
                    headingFontSize="14px"
                    supplement={`${formatYearMonthToJa(
                      carInfo.billInfo.billingMonthDeal,
                    )}の支払い`}
                    iconFontSize="28px"
                    iconType="alert"
                    color="danger"
                    heading={'引き落としが確認できませんでした'}
                  />
                </li>
              )}
          </ul>
          {!warranty.isExpired && showNextBill && (
            <CustomList
              path={paths.payments2Schedule(warranty?.warrantyNo)}
              text="次回請求予定"
              subText={[
                `請求予定日: ${hyphenToDayNoPadding(
                  carInfo.billInfo?.nextBillDate,
                  true,
                )}`,
                `請求金額: ${
                  carInfo.billInfo?.nextBillAmount.toLocaleString() ?? 0
                } 円`,
              ]}
              subTextStyle={{ fontSize: '14px', marginTop: '4px' }}
              fontSize="16px"
              isBold
              f7router={f7.view.main.router}
            />
          )}
          <CustomList
            text="お支払い履歴"
            subText={
              !!carInfo.billInfo.prevBillInfo &&
              carInfo.billInfo.prevBillInfo.no_pay_flg ===
                noPayFlg.BEFORE_PAYMENT &&
              (carInfo.billInfo.prevBillInfo.output_flg ===
                OUTPUT_FLAG.invoice ||
                carInfo.billInfo.prevBillInfo.output_flg ===
                  OUTPUT_FLAG.noSettlement)
                ? '前回の引き落としが確認できませんでした'
                : ''
            }
            subTextStyle={{
              fontSize: '14px',
              color: '#ff4655',
              marginTop: '4px',
            }}
            fontSize="16px"
            isBold
            handleClickList={() => {
              f7.view.main.router.navigate({
                // name of paths.paymentsHistory(warranty?.warrantyNo)
                name: '支払履歴',
                params: {
                  warranty_no: warranty?.warrantyNo,
                },
                query: {
                  historyType: historyType.WARRANTY,
                },
              });
            }}
            f7router={f7.view.main.router}
            path={''}
          />
        </div>
      )}
    </RoundSheet>
  );
};
