import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import { DriveScore } from './DriveScore/DriveScore';
import { getDriveScoreData } from './DriveScoreHelper';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useDriveScore } from '@/hooks/api/driveRecord/useDriveScore';

interface DriveScorePageProps {
  f7router: Router.Router;
}

export const DriveScorePage: FC<DriveScorePageProps> = ({ f7router }) => {
  const ownerId = store.state.linkDriveOwnerInfo.owner_id;
  const vehicleId = store.state.linkDriveOwnerInfo.vehicle_id;
  const mCustomerId = store.state.authInfo.m_customer_id;
  const userFamilyName = store.state.customerInfo.family_name;

  const driveScoreParams = {
    owner_id: ownerId,
    vehicle_id: vehicleId,
    m_customer_id: mCustomerId,
  };

  const { data, isLoading, error, isNoData } = useDriveScore(driveScoreParams);
  const driveScoreData = getDriveScoreData(data);

  const [currentDataType, setCurrentDataType] = useState('weekScore');

  const handleToggle = (item: string) => {
    setCurrentDataType(item);
    return;
  };

  const handleClickMonthlyDriveReport = () => {
    f7.view.main.router.navigate(paths.monthlyDriveReport);
  };

  return (
    <DriveScore
      driveScoreData={driveScoreData}
      currentDataType={currentDataType}
      isLoading={isLoading}
      isNoData={isNoData}
      userFamilyName={userFamilyName}
      handleToggle={handleToggle}
      handleClickMonthlyDriveReport={handleClickMonthlyDriveReport}
    />
  );
};
