import {
  addMonths,
  differenceInMonths,
  differenceInDays,
  areIntervalsOverlapping,
  parse,
} from 'date-fns';
import { format } from 'date-fns';
import { endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { deliveryCompleteFlg } from '@/consts/car';
import { prepaidFlg } from '@/consts/payment';
import {
  applyStatus as APPLY_STATUS,
  WARRANTY_STATUS,
} from '@/consts/warranty';
import {
  BillInformation,
  BillInformationDetail,
  NextWarranty,
  NextWarrantyAppendWarranty,
  PaymentMethod,
  WarrantiesAppendWarranty,
  Warranty,
} from '@/types/api/customerApi';
import { YYYYMM } from '@/types/date';
import { PrepaidFlg } from '@/types/payment';
import {
  dateToFullYearMonth,
  hyphenToDay,
  isInvalidDate,
  hyphenToDayNoPadding,
} from '@/utils/date';
import { getNextBillDetail } from '@/utils/prepaid';

export type CarInfoTiredToTheWarranty = {
  warranty: {
    warrantyNo: string;
    warrantyName: string;
    warrantyEndDate: string;
    warrantyStartDate: string;
    deductibleS: number;
    deductibleO: number;
    amountLimit: number;
    maxTrip: number;
    yearsLimit: number;
    updateMonthAgo: number;
    cancelEnableTerm: boolean;
    cancelEnableTermStart: string;
    cancelEnableTermEnd: string;
    isExpired: boolean;
  };
  nextWarranty: {
    warrantyStartDateNext: string;
    cancellationPeriodFrom: string;
    cancellationPeriodTo: string;
    cancelEnableTerm: boolean;
    cancelEnableTermStart: string;
    cancelEnableTermEnd: string;
    tBaseWarrantyId: number;
  };
  billInfo: {
    paymentTypeId: number;
    paymentTypeName: string;
    bankName: string;
    accountNumber: string;
    cardNo: string;
    nextBillDate: string;
    nextBillAmount: number;
    currentBillInfo: BillInformationDetail | undefined;
    nextBillInfo: BillInformationDetail | undefined;
    prevBillInfo: BillInformationDetail | undefined;
    billMonth: string;
    latestResult: string;
    billingMonthDeal: string;
  };
};

export type DisplayWarrantyItem = {
  warrantyName: string;
  warrantyStartDate: string;
  warrantyEndDate: string;
  deductibleS: number;
  deductibleO: number;
  amountLimit: number;
  maxTrip: number;
  yearsLimit: number;
  updateMonthAgo: number;
  isWarrantyStarted: boolean;
};

const SECOND_WARRANTY_INDEX = 1;

const findMonthlyBillInfo = (
  bill: BillInformation,
  month: YYYYMM | undefined | '',
  prepaidFlg: PrepaidFlg,
) => {
  if (!month) return undefined;
  return bill?.bill_infomation_details?.find(
    (bill) => bill.bill_month === month && bill.prepaid_flg === prepaidFlg,
  );
};

const makeBillInfo = (inputBill: BillInformation | undefined) => {
  let outputBill: CarInfoTiredToTheWarranty['billInfo'] = {
    paymentTypeId: 0,
    paymentTypeName: '',
    bankName: '',
    accountNumber: '',
    cardNo: '',
    nextBillDate: '',
    nextBillAmount: 0,
    currentBillInfo: undefined,
    nextBillInfo: undefined,
    prevBillInfo: undefined,
    billMonth: '',
    latestResult: '',
    billingMonthDeal: '',
  };
  if (!inputBill) return outputBill;

  const today = new Date();
  const currentMonth = dateToFullYearMonth(today);
  // eslint-disable-next-line no-magic-numbers
  const nextMonth = dateToFullYearMonth(addMonths(today, 1));
  const prevMonth = dateToFullYearMonth(addMonths(today, -1));
  if (inputBill?.bill_infomation_details) {
    const closestMonthBillInfo = getNextBillDetail(
      inputBill.bill_infomation_details,
      prepaidFlg.NO_HOUSE_PREPAID_EXISTS,
    );
    const currentBillInfo =
      findMonthlyBillInfo(
        inputBill,
        currentMonth,
        prepaidFlg.NO_HOUSE_PREPAID_EXISTS,
      ) ?? closestMonthBillInfo;
    const nextBillInfo =
      findMonthlyBillInfo(
        inputBill,
        nextMonth,
        prepaidFlg.NO_HOUSE_PREPAID_EXISTS,
      ) ?? closestMonthBillInfo;
    const prevBillInfo = findMonthlyBillInfo(
      inputBill,
      prevMonth,
      prepaidFlg.NO_HOUSE_PREPAID_EXISTS,
    );
    const billDetail = inputBill?.bill_infomation_details.slice(-1)[0];
    const newBill = {
      paymentTypeId: billDetail?.m_payment_type_id || 0,
      paymentTypeName: billDetail?.payment_type_name || '',
      bankName: billDetail?.bank_name || '',
      accountNumber: billDetail?.account_number || '',
      cardNo: billDetail?.card_no || '',
      billMonth: billDetail?.bill_month || '',
      currentBillInfo,
      nextBillInfo,
      prevBillInfo,
      billingMonthDeal: prevBillInfo?.bill_month || '',
    };
    outputBill = { ...outputBill, ...newBill };
  }
  return {
    ...outputBill,
    nextBillDate: inputBill.next_bill_date || '',
    nextBillAmount: inputBill.next_bill_amount || 0,
    latestResult: inputBill.latest_result || '',
  };
};

export const calcUpdateWarrantyMonthAgo = (warranty_end_date: string) => {
  const warrantyEndDate =
    warranty_end_date && parse(warranty_end_date, 'yyyy-MM-dd', new Date());
  if (warrantyEndDate instanceof Date) {
    return differenceInMonths(warrantyEndDate, new Date());
  }
  return 0;
};

export const getCarInfoTiredToTheWarranty = (
  warrantyNo: string,
  appendWarranty: WarrantiesAppendWarranty | undefined,
  nextWarranty: NextWarranty | undefined,
  billsInfo: BillInformation[] | undefined,
): CarInfoTiredToTheWarranty => {
  const billInfo = makeBillInfo(
    billsInfo?.find((x: BillInformation) => x.warranty_no === warrantyNo),
  );

  const cancelableWarrantyObj = makeCancelableWarrantyObj(
    appendWarranty?.warranty_end_date,
  );

  return {
    warranty: {
      warrantyNo,
      warrantyName: appendWarranty?.warranty_name ?? '',
      warrantyEndDate: appendWarranty?.warranty_end_date ?? '',
      warrantyStartDate: appendWarranty?.warranty_start_date ?? '',
      deductibleS: appendWarranty?.deductible_s ?? 0,
      deductibleO: appendWarranty?.deductible_o ?? 0,
      amountLimit: appendWarranty?.amount_limit ?? 0,
      maxTrip: appendWarranty?.max_trip ?? 0,
      yearsLimit: appendWarranty?.years_limit ?? 0,
      updateMonthAgo: calcUpdateWarrantyMonthAgo(
        appendWarranty?.warranty_end_date || '',
      ),
      cancelEnableTerm: isCancelEnableTerm(
        cancelableWarrantyObj?.cancelEnableTermStart ?? '',
        cancelableWarrantyObj?.cancelEnableTermEnd ?? '',
      ),
      cancelEnableTermStart: hyphenToDay(
        cancelableWarrantyObj?.cancelEnableTermStart ?? '',
        true,
      ),
      cancelEnableTermEnd: hyphenToDay(
        cancelableWarrantyObj?.cancelEnableTermEnd ?? '',
        true,
      ),
      isExpired: appendWarranty?.apply_status === WARRANTY_STATUS.EXPIRED,
    },
    nextWarranty: {
      warrantyStartDateNext:
        (nextWarranty?.t_base_waranty_id &&
          nextWarranty.append_warranties?.[0]?.warranty_start_date) ||
        '',
      cancellationPeriodFrom: nextWarranty?.cancellation_period_from ?? '',
      cancellationPeriodTo: nextWarranty?.cancellation_period_to ?? '',
      cancelEnableTerm: isCancelEnableTerm(
        nextWarranty?.cancellation_period_from ?? '',
        nextWarranty?.cancellation_period_to ?? '',
      ),
      cancelEnableTermStart: hyphenToDay(
        nextWarranty?.cancellation_period_from ?? '',
        true,
      ),
      cancelEnableTermEnd: hyphenToDay(
        nextWarranty?.cancellation_period_to ?? '',
        true,
      ),
      tBaseWarrantyId: nextWarranty?.t_base_waranty_id ?? 0,
    },
    billInfo,
  };
};

export const makeCancelableWarrantyObj = (
  warrantyEndDate: string | undefined,
) => {
  const cancelableWarrantyObj = {
    warrantyEndDate: warrantyEndDate || '',
    cancelEnableTermStart: '',
    cancelEnableTermEnd: '',
  };

  const date = parse(
    cancelableWarrantyObj.warrantyEndDate,
    'yyyy-MM-dd',
    new Date(),
  );

  if (!cancelableWarrantyObj.warrantyEndDate || isInvalidDate(date))
    return cancelableWarrantyObj;

  // 3ヶ月前の月の最初の日付
  cancelableWarrantyObj.cancelEnableTermStart = format(
    startOfMonth(subMonths(date, 3)),
    'yyyy-MM-dd',
  );
  // 2ヶ月前の月の最後の日付
  cancelableWarrantyObj.cancelEnableTermEnd = format(
    endOfMonth(subMonths(date, 2)),
    'yyyy-MM-dd',
  );

  return cancelableWarrantyObj;
};

export const isCancelEnableTerm = (ymdStart: string, ymdEnd: string) => {
  if (!ymdStart || !ymdEnd) return false;
  const date = new Date();
  const cancelEnableTermStart = parse(ymdStart, 'yyyy-MM-dd', new Date());
  const cancelEnableTermEnd = parse(ymdEnd, 'yyyy-MM-dd', new Date());
  return (
    differenceInDays(cancelEnableTermStart, date) <= 0 &&
    differenceInDays(cancelEnableTermEnd, date) >= 0
  );
};

// vue版での関数名: isHasbillInfomations
export const isPaymentMethodTiredToBills = (
  billInformation: BillInformation[] | undefined,
  warrantyNo: string,
) => {
  const billInfo = billInformation?.find(
    (x: BillInformation) => x.warranty_no === warrantyNo,
  );
  const paymentMethod =
    billInfo?.bill_infomation_details?.slice(-1)[0].m_payment_method_id;
  return !!paymentMethod || paymentMethod === 0;
};

export const isWarrantiesDuplicatedCheck = (warranties: Warranty[]) => {
  const validWarranties = getValidWarranties(warranties);
  const periodGroup = validWarranties.map((warranty) => [
    warranty?.append_warranties?.[0]?.warranty_start_date,
    warranty?.append_warranties?.[0]?.warranty_end_date,
  ]);
  const isDuplicated = periodGroup.some(([startDate, endDate], index) => {
    if (!startDate || !endDate) {
      return false;
    }
    let duplicated = false;
    periodGroup.forEach(([start, end], subIndex) => {
      if (index === subIndex) return;
      if (!start || !end) {
        return false;
      }
      if (
        areIntervalsOverlapping(
          {
            start: new Date(startDate),
            end: new Date(endDate),
          },
          {
            start: new Date(start),
            end: new Date(end),
          },
          {
            inclusive: true, // include end1 eq start2
          },
        )
      ) {
        duplicated = true;
      }
    });
    return duplicated;
  }, 0);
  return isDuplicated;
};

export const isMultipleWarrantiesCheck = (warranties: Warranty[]) => {
  if (warranties && warranties.length > SECOND_WARRANTY_INDEX) return true;
  return false;
};

export const makeDisplayWarrantyItemUnderChanging = (
  warranty: NextWarrantyAppendWarranty | undefined,
): DisplayWarrantyItem => {
  const initialWarranty: DisplayWarrantyItem = {
    warrantyName: '',
    warrantyStartDate: '',
    warrantyEndDate: '',
    deductibleS: 0,
    deductibleO: 0,
    amountLimit: 0,
    maxTrip: 0,
    yearsLimit: 0,
    updateMonthAgo: 0,
    isWarrantyStarted: false,
  };
  if (!warranty) return initialWarranty;
  return {
    warrantyName: warranty.warranty_name || '',
    warrantyStartDate: hyphenToDayNoPadding(
      warranty.warranty_start_date || '',
      true,
    ),
    warrantyEndDate: hyphenToDayNoPadding(
      warranty.warranty_end_date || '',
      true,
    ),
    deductibleS: warranty.deductible_s || 0,
    deductibleO: warranty.deductible_o || 0,
    amountLimit: Number(warranty.amount_limit) || 0,
    maxTrip: warranty.max_trip || 0,
    yearsLimit: warranty.years_limit || 0,
    updateMonthAgo: 0,
    isWarrantyStarted:
      differenceInDays(
        new Date(warranty.warranty_start_date || ''),
        new Date(),
      ) <= 0,
  };
};

export const makeDisplayWarrantyItemUnderContract = (
  warranty: WarrantiesAppendWarranty | undefined,
): DisplayWarrantyItem => {
  const initialWarranty: DisplayWarrantyItem = {
    warrantyName: '',
    warrantyStartDate: '',
    warrantyEndDate: '',
    deductibleS: 0,
    deductibleO: 0,
    amountLimit: 0,
    maxTrip: 0,
    yearsLimit: 0,
    updateMonthAgo: 0,
    isWarrantyStarted: false,
  };
  if (!warranty) return initialWarranty;
  return {
    warrantyName: warranty.warranty_name || '',
    warrantyStartDate: hyphenToDayNoPadding(
      warranty.warranty_start_date || '',
      true,
    ),
    warrantyEndDate: hyphenToDayNoPadding(
      warranty.warranty_end_date || '',
      true,
    ),
    deductibleS: warranty.deductible_s || 0,
    deductibleO: warranty.deductible_o || 0,
    amountLimit: warranty.amount_limit || 0,
    maxTrip: warranty.max_trip || 0,
    yearsLimit: warranty.years_limit || 0,
    updateMonthAgo: calcUpdateWarrantyMonthAgo(
      warranty.warranty_end_date || '',
    ),
    isWarrantyStarted:
      differenceInDays(
        new Date(warranty.warranty_start_date || ''),
        new Date(),
      ) <= 0,
  };
};

export const findWarrantyPaymentMethod = (
  warranty: Warranty | undefined,
  paymentMethods: PaymentMethod[] | undefined,
) => {
  if (!warranty || !paymentMethods?.length) return undefined;
  const { payment_method_id: paymentMethodId, payment_type_id: paymentTypeId } =
    warranty;
  const warrantyPaymentMethod = paymentMethods.find(
    (method) =>
      method.m_payment_method_id === paymentMethodId &&
      method.m_payment_type_id === paymentTypeId,
  );
  return warrantyPaymentMethod;
};

// vue版 Warranty.vueのinit()で行っていること
export const selectDisplayPayment = (
  currentBill: BillInformationDetail | undefined,
  nextBill: BillInformationDetail | undefined,
  warrantyPaymentMethod: PaymentMethod | undefined,
) => {
  return warrantyPaymentMethod || nextBill || currentBill;
};

export const findRelevantNextWarranty = (
  warranty?: Warranty,
  nextWarranties?: NextWarranty[],
) => {
  if (!warranty || !nextWarranties || nextWarranties.length === 0) return;
  const nextWarranty = nextWarranties.find(
    (next) =>
      next.warranty_no === warranty.warranty_no &&
      (warranty?.branch_no || 0) + 1 === next.branch_no,
  );
  return nextWarranty;
};

export const getValidWarranty = (warranties: Warranty[]) => {
  return warranties?.filter((warranty) => {
    const startDate = warranty?.append_warranties?.[0]?.warranty_start_date;
    if (!startDate) return false;
    return differenceInDays(new Date(startDate), new Date()) <= 0;
  });
};

export const getShownWarranties = (
  deliveryFlg: string | number,
  warranties?: Warranty[],
) => {
  if (!warranties) return [];
  const isDelivered = deliveryFlg != null && warranties.length !== 0;
  const validWarranties = getValidWarranty(warranties || []);
  if (!isDelivered) return [];
  if (
    deliveryFlg == deliveryCompleteFlg.BEFORE_DELIVERY &&
    validWarranties.length === 0
  ) {
    return warranties;
  }
  if (validWarranties.length === 0) return [];
  return validWarranties;
};

export const getValidWarranties = (warranties: Warranty[] = []) => {
  return warranties.filter((warranty) => {
    const appendWarranty = warranty?.append_warranties?.[0];
    if (!appendWarranty) return false;
    if (
      !appendWarranty?.warranty_start_date ||
      !appendWarranty.warranty_end_date
    )
      return false;
    // Expired Warranty
    if (appendWarranty?.warranty_status === WARRANTY_STATUS.EXPIRED) {
      return false;
    }
    // Future Warranty
    if (
      differenceInDays(
        new Date(appendWarranty?.warranty_start_date || Date.now()),
        new Date(),
      ) > 0
    ) {
      return false;
    }
    return true;
  });
};

export const listValidWarranties = (
  warranties: Warranty[] = [],
  nextWarranties?: NextWarranty[],
) => {
  return warranties.filter((warranty) => {
    const appendWarranty = warranty?.append_warranties?.[0];
    if (!appendWarranty) return false;
    if (
      !appendWarranty?.warranty_start_date ||
      !appendWarranty.warranty_end_date
    )
      return false;
    // Expired Warranty
    if (appendWarranty?.warranty_status === WARRANTY_STATUS.EXPIRED) {
      return false;
    }
    // Future Warranty
    if (
      differenceInDays(
        new Date(appendWarranty?.warranty_start_date || Date.now()),
        new Date(),
      ) > 0
    ) {
      return false;
    }

    if (
      appendWarranty?.apply_status ===
        APPLY_STATUS.DURING_CANCELLATION_APPLICATION ||
      appendWarranty?.apply_status ===
        APPLY_STATUS.OUTSIDE_CANCELLATION_APPLICATION
    ) {
      return false;
    }

    if (findRelevantNextWarranty(warranty, nextWarranties)) {
      return false;
    }

    return true;
  });
};

export const getExpiredWarranties = (warranties: Warranty[] = []) => {
  return warranties.filter((warranty) => {
    const appendWarranty = warranty?.append_warranties?.[0];
    if (!appendWarranty) {
      return false;
    }

    return appendWarranty?.warranty_status === WARRANTY_STATUS.EXPIRED;
  });
};
