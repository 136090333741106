import { Button, f7 } from 'framework7-react';
import { FC } from 'react';
import style from './RepairResultStyle.module.scss';
import { isApp } from '@/config/device';
import { url } from '@/consts/url';

type ReceivingFactoryProps = {
  factoryName?: string;
  factoryPhone?: string;
  factoryAddress?: string;
};

export const ReceivingFactory: FC<ReceivingFactoryProps> = ({
  factoryName = '',
  factoryPhone = '',
  factoryAddress = '',
}) => {
  const handleCallFactory = () => {
    if (factoryPhone) {
      window.location.href = 'tel://' + factoryPhone;
    }
  };

  const handleClickMap = () => {
    f7.view.main.router.navigate('/shopmap/', {
      props: {
        pageTitle: '入庫工場',
        parkingAddr: factoryAddress,
        isIcon: true,
      },
    });
  };
  return (
    <div className={style['common-part']}>
      <div className={style['header']}>入庫工場</div>
      <div className={style['block-background']}>
        <div className={style['block-ticket']}>
          <div
            className={`${style['content']} u-padding-top-0 u-padding-bottom-0`}
          >
            <div>
              <div>入庫工場</div>
              <div className={style['fw-700']}>{factoryName}</div>
            </div>
            <div className={style['icon-wrraper']}>
              <Button
                onClick={handleClickMap}
                className={style['button']}
                round
                iconSize="70px"
              >
                <i className={`icon-home-map`} />
              </Button>
              <Button
                onClick={handleCallFactory}
                className={style['button']}
                round
                iconSize="70px"
              >
                <i className={`icon-black-telephone`} />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <hr className={style['divider']} />
      <div className={`${style['desc']} u-padding-left-0`}>
        後ほど、弊社からご入庫先工場へ上記内容につきましてご連絡をいたします。​
      </div>
    </div>
  );
};
