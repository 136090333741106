export const statusReservation = {
  TENTATIVE: 0,
  COMPLETED: 1,
  CONFIRMED: 2,
  CANCEL: 9,
  DELETE: 99,
  NOT_ACCEPT: 3,
} as const;

export const serviceType = {
  carwash: 'carwash',
  airconditionerCleaning: 'airconditioner_cleaning',
  repairWarranty: 'repair_warranty',
  maintenance: 'maintenance',
};

export const repairStatus = {
  ACCEPTED: '修理受付完了',
  ROAD_SERVICE_IN_ARRANGED: 'ロードサービス手配中',
  TRANSPORTATION_COMPLETED: '搬送完了',
  FACTORY_ARRIVAL_COMPLETED: '工場入庫完了',
  INSPECTION_UNDERWAY: '審査中',
  REPAIR_REQUEST_COMPLETED: '審査完了',
  COMPLETED: '修理完了',
  RESPONSE_COMPLETED: '対応完了',
};

export const identificationSubscribers: Record<number, string> = {
  1: '本人',
  2: '代理店',
  3: '販売店',
  4: '工場',
};

export const caller = {
  MOBICON: 1,
  MAIKURU: 2,
};
