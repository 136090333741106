export const latestResult = {
  // TODO: 他のフラグ確認
  SUCCESS: '1',
} as const;

export const warrantyType = {
  // TODO: 値の意味が不明なため一旦命名は適当
  TYPE_ONE: 1,
} as const;

export const applyStatus = {
  NORMAL: 0,
  MIDTERM_CANCELLATION: 1,
  DURING_CANCELLATION_APPLICATION: 2,
  OUTSIDE_CANCELLATION_APPLICATION: 3,
  ALTERNATIVE: 4,
  REINSTATEMENT_APPLICATION: 5,
} as const;

export const warrantyCoverageType = {
  NOT_COVERED: 0,
  COVERED: 1,
};

export const PLAN_CHANGE_MONTH_BEFORE_EXPIRE = 2;

export const WARRANTY_STATUS = {
  EXPIRED: 1,
  VALID: 0,
};
