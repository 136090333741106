export const endpoint = Object.freeze({
  MAIKURU_API: import.meta.env.VITE_MAIKURU_API,
  MOBICON_API: import.meta.env.VITE_MOBICON_API,
  YAHOO_API: import.meta.env.VITE_YAHOO_API,
  GMO_LINK_API: import.meta.env.VITE_GMO_LINK_API,
  GMO_P01_API: import.meta.env.VITE_GMO_P01_API,
  BRAIN_TRAINING_LINK: import.meta.env.VITE_BRAIN_TRAINING_LINK,
  VITE_TELE_MILEAGE_LINK: import.meta.env.VITE_TELE_MILEAGE_LINK,
  VITE_TELE_MILEAGE_BACK_LINK: import.meta.env.VITE_TELE_MILEAGE_BACK_LINK,
  YAHOO_APPLICATION_ID: import.meta.env.VITE_YAHOO_APPLICATION_ID,
  GA_TRACKING_ID: import.meta.env.VITE_GA_TRACKING_ID,
});
