import { List, ListItem } from 'framework7-react';
import { FC, useMemo } from 'react';
import GreenTick from 'src/assets/images/green-tick.svg';
import { CommonTicketParts } from './CommonTicketParts';
import { ReceivingFactory } from './ReceivingFactory';
import { RepairParts } from './RepairParts';
import style from './RepairResultStyle.module.scss';
import { ResultFooter } from './ResultFooter';
import { APPROVAL_FLG } from '@/consts/carRepair';
import { MAffiliateSettingsApiResponse } from '@/types/api/infoApi';
import { RepairInfo } from '@/types/api/reservationApi';

type RepairResultProps = {
  data?: RepairInfo;
  affiliateData?: MAffiliateSettingsApiResponse['data'];
};

export const RepairResult: FC<RepairResultProps> = ({
  data,
  affiliateData,
}) => {
  const isAllApplicable = data?.repair_details?.every(
    (item) => item.approval_flg === APPROVAL_FLG.APPLICABLE,
  );
  const isAllNotApplicable =
    data?.repair_details?.length &&
    data?.repair_details?.every(
      (item) => item.approval_flg === APPROVAL_FLG.NOT_APPLICABLE,
    );
  const warrantyResultOpt = useMemo(() => {
    let reasultClass = style['warranty-reasult'];
    let reasultHeaderClass = style['header'];
    let reasultHeader = '';
    if (isAllApplicable) {
      reasultHeader = 'すべて保証対象です';
    } else if (isAllNotApplicable) {
      reasultHeader = '保証対象外です';
      reasultClass += ` ${style['warranty-reasult-secondary']}`;
      reasultHeaderClass += ` ${style['header-radius']}`;
    } else {
      reasultHeader = '一部を除いて保証対象です';
      reasultHeaderClass += ` ${style['header-radius']}`;
    }
    return {
      reasultHeader,
      reasultClass,
      reasultHeaderClass,
    };
  }, [isAllApplicable, isAllNotApplicable]);
  const partsFromOpt = useMemo(() => {
    const listPartApplicable = data?.repair_details?.reduce(
      (list, { approval_flg, parts_name, parts_type, is_discount_flg }) => {
        if (approval_flg === APPROVAL_FLG.APPLICABLE && !is_discount_flg) {
          const part = `${parts_name}${parts_type ? ` (${parts_type})` : ''}`;
          list.push(part);
        }
        return list;
      },
      [] as string[],
    );

    const listNotApplicable = data?.repair_details.filter(
      (item) => item.approval_flg === APPROVAL_FLG.NOT_APPLICABLE,
    );

    const listPartNotApplicable = listNotApplicable?.map((item) => ({
      parts_name: item.parts_name,
      reason: item.reason_of_not_covered,
    }));

    const subTotalInitial = listNotApplicable?.reduce(
      (total, { sub_total_initial }) => total + sub_total_initial,
      0,
    );
    return { listPartApplicable, listPartNotApplicable, subTotalInitial };
  }, [data]);

  const chargesEstOpt = useMemo(() => {
    const ticketList = [];
    if (partsFromOpt?.subTotalInitial) {
      ticketList.push({
        label: '保証対象外費用お見積り（税抜）',
        value: partsFromOpt?.subTotalInitial,
      });
    }
    return {
      desc: '対象外となりました箇所の修理をご希望される場合、該当の修理につきましてはお客様ご負担となります。',
      title:
        'お支払い方法については、お手数ではございますがご入庫先工場にてご確認ください。',
      ticketList,
    };
  }, [partsFromOpt?.subTotalInitial]);

  const chargesOpt = useMemo(() => {
    const tempList = [];
    let ticketList = [];
    let desc =
      '割合負担の保証商品でございますので、以下のご負担が発生いたします。';
    let title =
      'お支払い方法については、お手数ではございますがご入庫先工場にてご確認ください。';

    if (data?.user_expense_percentage) {
      ticketList.push({
        label: 'ご負担割合',
        value: data?.user_expense_percentage,
        isPercentage: true,
      });
    }
    if (data?.user_expense) {
      ticketList.push({
        label: 'ご負担金額（税込）',
        value: data?.user_expense,
      });
    }
    if (ticketList.length) {
      tempList.push({
        desc,
        title,
        ticketList,
      });
    }
    if (data?.user_expense_over_limit) {
      tempList.push({
        desc: '保証限度額を超過しておりますため、超過分のご負担が発生いたします。',
        title,
        ticketList: [
          {
            label: 'ご負担金額（税込）',
            value: data?.user_expense_over_limit,
          },
        ],
      });
    }

    if (chargesEstOpt?.ticketList?.length) {
      tempList.push(chargesEstOpt);
    }

    return tempList;
  }, [
    data?.user_expense_percentage,
    data?.user_expense,
    data?.user_expense_over_limit,
    chargesEstOpt,
  ]);

  const deductibleOpt = useMemo(() => {
    const tempList = [];
    const desc = '修理着工にあたりまして免責金のお支払が必要です。';
    const title =
      'お支払い方法については、お手数ではございますがご入庫先工場にてご確認ください。';

    if (data?.deductible) {
      tempList.push({
        desc,
        title,
        ticketList: [
          {
            label: '免責金（税込）',
            value: data?.deductible,
          },
        ],
      });
    }
    return tempList;
  }, [data?.deductible]);

  return (
    <div className={style['container']}>
      <div className={style['header-notice']}>
        <img src={GreenTick} alt="green tick" />
        審査結果
      </div>
      {/* Warranty applicability result */}
      <div className={warrantyResultOpt.reasultClass}>
        <div className={warrantyResultOpt.reasultHeaderClass}>
          {warrantyResultOpt.reasultHeader}
        </div>
        {!isAllApplicable ? (
          <div className={style['content']}>
            対象外内容
            <List menuList mediaList className={style['list']}>
              {partsFromOpt?.listPartNotApplicable?.map(
                ({ parts_name, reason }, idx) => (
                  <ListItem key={`${parts_name}-${idx}`}>
                    <div className={style['title']}>{parts_name}</div>
                    <div className={style['text-mark']}>{reason}</div>
                  </ListItem>
                ),
              )}
            </List>
            <div className={style['warranty-reasult-amount']}>
              <p>見積もり合計金額（税抜）</p>
              <p className={style['amount']}>
                {partsFromOpt?.subTotalInitial?.toLocaleString()}円
              </p>
            </div>
            <div className={style['desc']}>
              修理につきましては、ご入庫先工場とご相談くださいませ。
            </div>
          </div>
        ) : null}
      </div>
      {!isAllNotApplicable ? (
        <>
          {/* 修理使用部品及び実施作業  */}
          {partsFromOpt?.listPartApplicable?.length ? (
            <RepairParts
              header="修理使用部品及び実施作業"
              desc="修理に使用する部品、及び実施作業は以下になります。"
              blockList={partsFromOpt?.listPartApplicable}
            />
          ) : null}
          {/* ご負担金  */}
          <CommonTicketParts header="ご負担金" listPart={chargesOpt} />
          {/* 免責金  */}
          <CommonTicketParts header="免責金" listPart={deductibleOpt} />
        </>
      ) : null}
      {/* 入庫工場  */}
      <ReceivingFactory
        factoryName={data?.nyukosaki_name}
        factoryPhone={data?.nyukosaki_phone_num}
        factoryAddress={data?.nyukosaki_address}
      />
      {/* この後の流れ */}
      <ResultFooter
        data={affiliateData?.data}
        isShowRecommend={!isAllNotApplicable}
      />
    </div>
  );
};
