import { Card, Link } from 'framework7-react';
import { Router } from 'framework7/types';
import parse from 'html-react-parser';
import { FC } from 'react';
import { ArrowIconLabelButton } from '../ArrowIconLabelButton';
import style from './MonthlyDriveReportStyle.module.css';
import { Co2Report } from '@/components/pages/MonthlyDriveReport/Co2Report/Co2Report';
import { AlertCount } from '@/components/projects/AlertCount/AlertCount';
import { DriveScore } from '@/components/projects/DriveScore/DriveScore';
import { DrivingReportScoreStar } from '@/components/projects/DrivingReportScoreStar/DrivingReportScoreStar';
import { TransitionChart } from '@/components/projects/TransitionChart/TransitionChart';
import { OpenUrlIcon } from '@/components/uiParts/Icon';
import { InfoCard } from '@/components/uiParts/InfoCard/InfoCard';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { MonthlyDriveReportApiResponse } from '@/types/api/driveRecordApi';
import './MonthlyDriveReportStyle.scss';

interface MonthlyDriveReportProps {
  f7router: Router.Router;
  data?: MonthlyDriveReportApiResponse;
  isPrevMonthBtnDisabled: boolean;
  isNextMonthBtnDisabled: boolean;
  isLoading: boolean;
  isNoData: boolean;
  clickPrevMonth: () => void;
  clickNextMonth: () => void;
}
export const MonthlyDriveReport: FC<MonthlyDriveReportProps> = ({
  f7router,
  data,
  isPrevMonthBtnDisabled,
  isNextMonthBtnDisabled,
  isLoading,
  isNoData,
  clickPrevMonth,
  clickNextMonth,
}) => {
  const pieces = [
    {
      gt: 80,
      lte: 100,
      color: '#1E55E6',
    },
    {
      gt: 60,
      lte: 79,
      color: '#00DCFA',
    },
    {
      gt: 0,
      lte: 59,
      color: '#FFBF40',
    },
  ];

  const starList = [
    {
      type: 'スピード',
      score: data?.stars_speed ?? 0,
    },
    {
      type: 'アクセル',
      score: data?.stars_acceleration ?? 0,
    },
    {
      type: 'ブレーキ',
      score: data?.stars_deceleration ?? 0,
    },
  ];

  return (
    <PageTemplate
      pageName="MonthlyDriveReport"
      title="月間安全運転レポート"
      showNavBack={true}
    >
      <div className={style.container}>
        {!isLoading && !isNoData && data && (
          <>
            <RoundSheet>
              <div className={style.switchingMonthsWrapper}>
                <ArrowIconLabelButton
                  type="prev"
                  label="前月"
                  onClick={clickPrevMonth}
                  disabled={isPrevMonthBtnDisabled}
                />
                <div className={style.periodWrapper}>
                  <div className={style.monthly}>
                    {data.reportDate.substring(0, 7)}
                  </div>
                </div>
                <ArrowIconLabelButton
                  type="next"
                  label="次月"
                  onClick={clickNextMonth}
                  disabled={isNextMonthBtnDisabled}
                />
              </div>
              <h3 className={style.mt16px}>月間安全運転スコア</h3>
              <div className={style.scoreWrapper}>
                <div className={style.driveScoreWrapper}>
                  <DriveScore score={data.averageScore} />
                </div>
                <div className={style.drivingReportScoreStarWrapper}>
                  <DrivingReportScoreStar scoreList={starList} />
                </div>
              </div>
              {data.co2_kg !== null && <Co2Report co2Kg={data.co2_kg} />}
              <div className={style.scoreCommentWrapper}>
                {parse(data.scoreComment ?? '')}
              </div>
              <div>
                <div style={{ width: '100%', height: '18rem' }}>
                  <TransitionChart
                    id="monthlyTranstion"
                    yearScoreList={data.yearScoreList}
                    createMonthly={data.createMonthly}
                    showLabel={false}
                    showSplitLineX={false}
                    gridLeft="10%"
                    seriesType="bar"
                    minNumber={0}
                    maxNumber={100}
                    axisLabelInterval={1}
                    pieces={pieces}
                  />
                </div>
                <div className="level-desc-bar u-margin-top-sm">
                  <div>判定</div>
                  <div>
                    <span>A</span>
                  </div>
                  <div>
                    <span>B</span>
                  </div>
                  <div>
                    <span>C</span>
                  </div>
                </div>
              </div>
            </RoundSheet>
            {/* TODO: 以下リファクタリング */}
            {/* <!-- alert実績 --> */}
            <RoundSheet>
              <h3 className={`${style['mt0']} ${style['font16']}`}>
                アラート実績
              </h3>
              <div>
                <h3 className={style['font14']}>速度超過</h3>
                <AlertCount
                  total={data.eventSpeed}
                  average={data.averageEventSpeed}
                />
              </div>
              <div>
                <h3 className={style['font14']}>急アクセル</h3>
                <AlertCount
                  total={data.eventAcceralatedStability}
                  average={data.averageEventAcceralatedStability}
                />
                {/* <AlertItem
                  title="急アクセル"
                  dayList={data.dayList}
                  serialDataList={data.suddenStartDataList}
                  total={data.eventAcceralatedStability}
                  average={data.averageEventAcceralatedStability}
                  isGraph
                  width={95}
                  unitXLabel="回"
                  unitYLabel="日"
                /> */}
              </div>
              <div>
                <h3 className={style['font14']}>急ブレーキ</h3>
                <AlertCount
                  total={data.eventDowndecelerateStability}
                  average={data.averageEventDowndecelerateStability}
                />
                {/* <AlertItem
                  title="急ブレーキ"
                  dayList={data.dayList}
                  serialDataList={data.suddenBrakingDataList}
                  total={data.eventDowndecelerateStability}
                  average={data.averageEventDowndecelerateStability}
                  isGraph
                  width={95}
                  unitXLabel="回"
                  unitYLabel="日"
                /> */}
              </div>
              <div className="u-padding-top-md">
                <InfoCard
                  className="valuation_card"
                  url={'/evaluation_details/'}
                  text="アラートの判定基準"
                  textSub=""
                  iconType="help"
                  bgColor="muted"
                  isElevated={false}
                  isIconRight={true}
                  onClick={() => {}}
                />
              </div>
            </RoundSheet>
            {/* <!-- 走行実績 --> */}
            <RoundSheet>
              <h3 className={`${style['mt0']} ${style['font16']}`}>走行実績</h3>
              <div className={style['mt16px'] + ' driving-info-card_line1'}>
                <div className="driving-info-card_line1_total">
                  <div>走行回数</div>
                  <div className="driving-info-card_line1_total_number">
                    <span>{data.tripCount}</span> 回
                  </div>
                </div>
                <div className="driving-info-card_line1_a">
                  <div className="driving-info-card_line1_a_label">
                    <span>A判定</span>
                    <span>80 ~ 100点</span>
                  </div>
                  <div className="driving-info-card_line1_a_number">
                    <span>{data.resultValueA}</span>回
                  </div>
                </div>
                <div className="driving-info-card_line1_b">
                  <div className="driving-info-card_line1_b_label">
                    <span>B判定</span>
                    <span>60 ~ 79点</span>
                  </div>
                  <div className="driving-info-card_line1_b_number">
                    <span>{data.resultValueB}</span>回
                  </div>
                </div>
                <div className="driving-info-card_line1_c">
                  <div className="driving-info-card_line1_c_label">
                    <span>C判定</span>
                    <span>~ 59点</span>
                  </div>
                  <div className="driving-info-card_line1_c_number">
                    <span>{data.resultValueC}</span>回
                  </div>
                </div>
              </div>
              <div className="driving-info-card_line2">
                <div className="driving-info-card_line2_1">
                  <div className={style['font12']}>走行距離</div>
                  <div className="number">
                    <span>{data.drivingDistance}</span> km
                  </div>
                </div>
                <div className="driving-info-card_line2_2">
                  <div className={style['font12']}>平均走行距離</div>
                  <div className="number">
                    <span>{data.averageDrivingDistance}</span> km / 走行
                  </div>
                </div>
              </div>
              <div className="driving-info-card_line3">
                <div className="driving-info-card_line2_1">
                  <div className={style['font12']}>走行時間</div>
                  <div className="number">
                    <span>{data.drivingHours}</span> 時間
                    <span> {data.drivingMinutes}</span> 分
                  </div>
                </div>
                <div className="driving-info-card_line2_2">
                  <div className={style['font12']}>平均走行時間(走行毎)</div>
                  <div className="number">
                    <span>{data.averageDrivingHours}</span> 時間
                    <span> {data.averageDrivingMinutes}</span> 分 / 走行
                  </div>
                </div>
              </div>
            </RoundSheet>
            {!!data?.game_id && (
              <RoundSheet>
                <h3
                  className={`${style['mt0']} ${style['font16']} u-margin-bottom-md`}
                >
                  おすすめメニュー
                </h3>
                <div className={'brain-training-card'}>
                  {data?.game_title}
                  <Link
                    text="プレイする"
                    href={data?.game_url}
                    target="_blank"
                    className="external"
                  >
                    <OpenUrlIcon />
                  </Link>
                </div>
              </RoundSheet>
            )}
          </>
        )}
        {!isLoading && isNoData && (
          <div className="block__no-data">
            <div className="no-data u-text-center u-font-size-xxl">
              データーがありません
            </div>
            {[]}
          </div>
        )}
        {isLoading && (
          <div>
            <Card
              className="skeleton-text"
              title="Card Header"
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum. Cras consequat felis at consequat hendrerit."
              footer="Card Footer"
            />
          </div>
        )}
      </div>
    </PageTemplate>
  );
};
