import { Router } from 'framework7/types';
import { FC } from 'react';
import { HasNoWarranty } from '../HasNoWarranty/HasNoWarranty';
import { WarrantyInfoGroup } from '../WarrantyInfoGroup/WarrantyInfoGroup';
import {
  CarInfoTiredToTheWarranty,
  getShownWarranties,
} from '../WarrantyManagementHelper';
import styles from './WarrantyManagement.module.css';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import {
  BillInformationDetail,
  NextWarranty,
  PaymentMethod,
  WarrantiesAppendWarranty,
  Warranty,
} from '@/types/api/customerApi';

interface WarrantyManagementProps {
  deliveryFlg: string | number;
  carInfoTiredToTheWarranty: CarInfoTiredToTheWarranty;
  f7router: Router.Router;
  warranties: Warranty[];
  nextWarranties: NextWarranty[];
  warrantyNo: string;
  nextBaseWarrantyId: number | undefined;
  appendWarranty: WarrantiesAppendWarranty | undefined;
  displayPayment: BillInformationDetail | Required<PaymentMethod> | undefined;
  handleClickBack: () => void;
  handleClickChangePlan: () => void;
  handleClickChangeSetElementMethod: () => void;
}

export const WarrantyManagement: FC<WarrantyManagementProps> = ({
  deliveryFlg,
  carInfoTiredToTheWarranty,
  f7router,
  warranties,
  nextWarranties,
  warrantyNo,
  appendWarranty,
  displayPayment,
  handleClickBack,
  handleClickChangeSetElementMethod,
}) => {
  const shownWarranties = getShownWarranties(deliveryFlg, warranties);
  // pathが(/warranty/null/)の場合の出しわけ
  const ComponentToDisplay = shownWarranties.length ? (
    <WarrantyInfoGroup
      f7router={f7router}
      displayPayment={displayPayment}
      carInfoTiredToTheWarranty={carInfoTiredToTheWarranty}
      warranties={shownWarranties}
      nextWarranties={nextWarranties}
      warrantyNo={warrantyNo}
      appendWarranty={appendWarranty}
      handleClickChangeSetElementMethod={handleClickChangeSetElementMethod}
    />
  ) : (
    <HasNoWarranty />
  );

  return (
    <PageTemplate
      pageName="WarrantyManagement"
      title="保証管理"
      showNavBack
      handleClickBack={handleClickBack}
    >
      <div className={styles['container']}>{ComponentToDisplay}</div>
    </PageTemplate>
  );
};
