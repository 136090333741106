import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { isWarrantiesDuplicatedCheck } from '../WarrantyManagement/WarrantyManagementHelper';
import { UpdateWarrantyDetailComponent } from './UpdateWarrantyDetailComponent/UpdateWarrantyDetailComponent';
import {
  checkIfAllowToChangePlan,
  makeUpdateWarrantyDetailCustomer,
} from './updateWarrantyDetailHelper';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useGuaranteeUpdateTarget } from '@/hooks/api/warranty/useGuaranteeUpdateTarget';
import { storeDispatch } from '@/utils/store';
import { findAppendWarrantyOfTypeOne } from '@/utils/warranty';

interface UpdateWarrantyDetailPageProps {
  f7router: Router.Router;
  warranty_no: string;
}

export const UpdateWarrantyDetailPage: FC<UpdateWarrantyDetailPageProps> = ({
  f7router,
  warranty_no,
}) => {
  const {
    customerInfo,
    carItemInfo,
    warranties,
    nextWarranty,
    currentWarranty,
    billsInfo,
  } = store.state;
  const warrantyNo = warranty_no;

  const warranty = findAppendWarrantyOfTypeOne(currentWarranty);
  const isWarrantiesDuplicated = isWarrantiesDuplicatedCheck(warranties);

  const isAllowToChangePlan = checkIfAllowToChangePlan(
    isWarrantiesDuplicated,
    warranty?.warranty_end_date,
  );

  const { data } = useGuaranteeUpdateTarget({
    warranty_no: warrantyNo,
    branch_no: currentWarranty?.branch_no ?? 0,
  });

  const customer = makeUpdateWarrantyDetailCustomer(
    warrantyNo,
    billsInfo,
    customerInfo,
    carItemInfo,
    data?.continuous_warranties,
    nextWarranty,
    warranty,
  );

  const handleClickButton = () => {
    storeDispatch('setPaymentTempData', 'paymentTempData', {
      type: 'prepaid',
      openDialogName: `/reservings_start/`,
      isTransition: false,
    });
    f7.view.main.router.navigate(paths.updateWarranty(warrantyNo));
  };

  const handleClickCancel = () => {
    if (customer.cancelEnableTerm) {
      f7.view.main.router.navigate(paths.cancelWarranty);
    } else {
      f7.view.main.router.navigate(paths.warrantyCancelable(warrantyNo));
    }
  };

  return (
    <UpdateWarrantyDetailComponent
      f7router={f7router}
      handleClickCancel={handleClickCancel}
      handleClickButton={handleClickButton}
      isUpdatable={!!currentWarranty?.auto_continuous_flg}
      isAllowToChangePlan={isAllowToChangePlan}
      customer={customer}
    />
  );
};
