import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchDriveRapidDecelerationApi } from '@/api/driveRecordApi';
import { RapidDecelerationParams } from '@/types/api/driveRecordApi';
import { createMonths, createOneWeek, doHandleMonth } from '@/utils/date';
import { echartsScale } from '@/utils/echartsScail';

export const useRapidDecelerationData = (
  initialParams: RapidDecelerationParams,
) => {
  const [params] = useState(initialParams);
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(true);
  const [error, setError] = useState<AxiosError>();

  const initialData = {
    rapidDecelerationWeeklyInfo: {
      weeklyScoreList: [0, 0, 0, 0, 0, 0, 0],
      createWeekly: [''],
      morningCounts: 0,
      afternoonCounts: 0,
      eveningCounts: 0,
      rapidDecelerationScale: {
        minNumber: 0,
        maxNumber: 10,
        YaxisLabelInterval: 1,
      },
    },
    rapidDecelerationMonthlyInfo: {
      monthlyScoreList: [0, 0, 0, 0, 0, 0],
      createMonthly: [''],
      morningCounts: 0,
      afternoonCounts: 0,
      eveningCounts: 0,
      rapidDecelerationScale: {
        minNumber: 0,
        maxNumber: 10,
        YaxisLabelInterval: 1,
      },
    },
    rapidDecelerationAllInfo: {
      allScoreList: [0],
      createAll: [''],
      morningCounts: 0,
      afternoonCounts: 0,
      eveningCounts: 0,
      rapidDecelerationScale: {
        minNumber: 0,
        maxNumber: 10,
        YaxisLabelInterval: 1,
      },
    },
    progressWidth: {
      morningProgress: 0,
      afternoonProgress: 0,
      eveningProgress: 0,
    },
    rapidDecelerationInfo: {
      yearScoreList: [],
      createMonthly: [],
      morningCounts: 0,
      afternoonCounts: 0,
      eveningCounts: 0,
    },
  };

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchDriveRapidDecelerationApi(params);

        const weeklyDateList = createOneWeek();
        initialData.rapidDecelerationWeeklyInfo.createWeekly = weeklyDateList;
        weeklyDateList.forEach((item: any, index: number) => {
          res?.data.data.rapid_deceleration_times_past_week.find((n) => {
            if (n.start_at_ymd?.substring(6, 8) === item.substring(5, 7)) {
              initialData.rapidDecelerationWeeklyInfo.weeklyScoreList[index] =
                n.rapid_deceleration_times_avg ?? 0;
            }
          });
        });
        initialData.rapidDecelerationWeeklyInfo.rapidDecelerationScale =
          echartsScale(
            'rapidDeceleration',
            initialData.rapidDecelerationWeeklyInfo.weeklyScoreList,
          ) ?? initialData.rapidDecelerationWeeklyInfo.rapidDecelerationScale;

        //過去6ヶ月のデータ
        const currentMonthlyDate = new Date();
        const currentMonthlyYear = currentMonthlyDate.getFullYear();
        const currentMonth = doHandleMonth(currentMonthlyDate.getMonth() + 1);
        const latestDate = currentMonthlyYear + '/' + currentMonth;
        const monthlyDateList = createMonths(latestDate).reverse();
        initialData.rapidDecelerationMonthlyInfo.createMonthly =
          monthlyDateList;
        monthlyDateList.forEach((item: any, index: number) => {
          res.data.data.rapid_deceleration_times_last_six_month?.find((n) => {
            if (n.start_at_ym?.substring(4, 6) === item.substring(0, 2)) {
              initialData.rapidDecelerationMonthlyInfo.monthlyScoreList[index] =
                n.rapid_deceleration_times_avg ?? 0;
            }
          });
        });
        initialData.rapidDecelerationMonthlyInfo.rapidDecelerationScale =
          echartsScale(
            'rapidDeceleration',
            initialData.rapidDecelerationMonthlyInfo.monthlyScoreList,
          ) ?? initialData.rapidDecelerationMonthlyInfo.rapidDecelerationScale;

        // 全期間（直近4年分のデータを取得）
        const allDateList: string[] = [];
        const allScores: number[] = [];
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        for (let year = currentYear - 3; year <= currentYear; year++) {
          for (let month = 1; month <= 12; month++) {
            const formattedMonth = month.toString().padStart(2, '0');
            const itemDate = formattedMonth + '月\n' + year.toString();
            allDateList.push(itemDate);
            const foundItem = res.data.data.rapid_deceleration_times_all?.find(
              (n: any) =>
                n.start_at_ym?.substring(4, 6) === formattedMonth &&
                n.start_at_ym?.substring(0, 4) === year.toString(),
            );
            if (foundItem) {
              allScores.push(foundItem.rapid_deceleration_times_avg ?? 0);
            } else {
              allScores.push(0);
            }
          }
        }

        initialData.rapidDecelerationAllInfo.createAll = allDateList;
        initialData.rapidDecelerationAllInfo.allScoreList = allScores;
        initialData.rapidDecelerationAllInfo.rapidDecelerationScale =
          echartsScale(
            'rapidDeceleration',
            initialData.rapidDecelerationAllInfo.allScoreList,
          ) ?? initialData.rapidDecelerationAllInfo.rapidDecelerationScale;

        //朝昼夜の傾向
        // 朝、昼、夜
        let morningData =
          res.data.data.rapid_deceleration_times_time_zone?.find(
            (n: any) => n.time_zone == 1,
          );
        let afternoonData =
          res.data.data.rapid_deceleration_times_time_zone?.find(
            (n: any) => n.time_zone == 2,
          );
        let eveningData =
          res.data.data.rapid_deceleration_times_time_zone?.find(
            (n: any) => n.time_zone == 3,
          );

        initialData.rapidDecelerationInfo.morningCounts =
          morningData?.rapid_deceleration_times_avg ?? 0;
        initialData.rapidDecelerationInfo.afternoonCounts =
          afternoonData?.rapid_deceleration_times_avg ?? 0;
        initialData.rapidDecelerationInfo.eveningCounts =
          eveningData?.rapid_deceleration_times_avg ?? 0;

        initialData.progressWidth.morningProgress =
          ((morningData?.rapid_deceleration_times_avg ?? 0) / 5) * 100;
        initialData.progressWidth.afternoonProgress =
          ((afternoonData?.rapid_deceleration_times_avg ?? 0) / 5) * 100;
        initialData.progressWidth.eveningProgress =
          ((eveningData?.rapid_deceleration_times_avg ?? 0) / 5) * 100;

        setData(initialData);
        setIsNoData(false);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [params]);

  return {
    data,
    isLoading,
    isNoData,
    error,
  };
};
