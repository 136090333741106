import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchDriveScoreApi } from '@/api/driveRecordApi';
import { PhydScoreParams, PhydScoreResponse } from '@/types/api/driveRecordApi';

export const useDriveScore = ({
  owner_id,
  vehicle_id,
  m_customer_id,
}: PhydScoreParams) => {
  const [data, setData] = useState<PhydScoreResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchDriveScoreApi({
          owner_id,
          vehicle_id,
          m_customer_id,
        });

        if (!res.data.success) {
          setIsNoData(true);
        }

        setData(res.data);
      } catch (err) {
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [owner_id, vehicle_id, m_customer_id]);

  return {
    data,
    isLoading,
    isNoData,
    error,
  };
};
