import { Block, Card } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import { BrakesProcess } from '@/components/projects/BrakesProcess/BrakesProcess';
import { TransitionChart } from '@/components/projects/TransitionChart/TransitionChart';
import { InfoCard } from '@/components/uiParts/InfoCard/InfoCard';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useRapidAcceleration } from '@/hooks/api/driveRecord/useRapidAcceleration';
import './AccelerationDataStyle.scss';

interface AccelerationProps {
  f7router: Router.Router;
}

export const AccelerationData: FC<AccelerationProps> = ({ f7router }) => {
  const ownerId = store.state.linkDriveOwnerInfo.owner_id;

  const accelerationParams = {
    owner_id: ownerId,
  };

  const { data, isLoading, isNoData } =
    useRapidAcceleration(accelerationParams);

  const changeBtnsList = [
    { label: '日毎', subLabel: '過去7日間', flag: 'week' },
    { label: '月毎', subLabel: '過去6ヶ月', flag: 'halfYear' },
    {
      label: '全期間',
      subLabel: String(new Date().getFullYear() - 3) + '年01月~',
      flag: 'all',
    },
  ];

  const [currentDataType, setCurrentDataType] = useState('week');

  const handleToggle = (item: string) => {
    setCurrentDataType(item);
    return;
  };

  // 全期間のラベルを1月と7月のみにする
  const createAllFiltered: string[] = [];

  if (data?.rapidAccelerationAllInfo.createAll) {
    for (const item of data?.rapidAccelerationAllInfo.createAll) {
      const monthNumber = parseInt(item.substring(0, 2), 10);
      if (monthNumber === 1 || monthNumber === 7) {
        createAllFiltered.push(item);
      } else {
        createAllFiltered.push('');
      }
    }
  }

  return (
    <PageTemplate
      pageName="DrivingReport"
      title="急アクセル回数"
      showNavBack={true}
      inlinePageStyle={{ paddingTop: '40px' }}
    >
      <Block>
        <div className="u-padding-bottom-sm">
          <InfoCard
            url={paths.evaluationDetails}
            text="急アクセル回数の判定基準"
            textSub=""
            bgColor="muted"
            isElevated
            isIconRight={true}
            iconType=""
          />
        </div>
        <div className="info-card info-card-elevated u-margin-bottom-md">
          <h2 className="u-font-size-xxl">
            急アクセル回数（
            {currentDataType === 'week' ? '日の平均値' : '月の平均値'}）
          </h2>
          {/* <!-- 過去一週間 --> */}
          {currentDataType == 'week' && (
            <div style={{ width: '100%', height: '18rem' }}>
              <TransitionChart
                id="currentDataType"
                yearScoreList={
                  data?.rapidAccelerationWeeklyInfo.weeklyScoreList
                }
                createMonthly={data?.rapidAccelerationWeeklyInfo.createWeekly}
                showLabel={false}
                showSplitLineX={false}
                minNumber={
                  data?.rapidAccelerationWeeklyInfo.rapidAccelerationScale
                    .minNumber
                }
                maxNumber={
                  data?.rapidAccelerationWeeklyInfo.rapidAccelerationScale
                    .maxNumber
                }
                axisLabelIntervalY={
                  data?.rapidAccelerationWeeklyInfo.rapidAccelerationScale
                    .YaxisLabelInterval
                }
                axisLabelUnit="回"
                gridLeft="14%"
                seriesType="bar"
                axisLabelInterval={0}
              />
            </div>
          )}
          {/* <!-- 過去六ヶ月 --> */}
          {currentDataType == 'halfYear' && (
            <div style={{ width: '100%', height: '18rem' }}>
              <TransitionChart
                id="currentDataType"
                yearScoreList={
                  data?.rapidAccelerationMonthlyInfo.monthlyScoreList
                }
                createMonthly={data?.rapidAccelerationMonthlyInfo.createMonthly}
                showLabel={false}
                showSplitLineX={false}
                minNumber={
                  data?.rapidAccelerationMonthlyInfo.rapidAccelerationScale
                    .minNumber
                }
                maxNumber={
                  data?.rapidAccelerationMonthlyInfo.rapidAccelerationScale
                    .maxNumber
                }
                axisLabelIntervalY={
                  data?.rapidAccelerationMonthlyInfo.rapidAccelerationScale
                    .YaxisLabelInterval
                }
                axisLabelUnit="回"
                gridLeft="14%"
                seriesType="bar"
                axisLabelInterval={0}
              />
            </div>
          )}
          {/* <!-- 全期間 --> */}
          {currentDataType == 'all' && (
            <div style={{ width: '100%', height: '18rem' }}>
              <TransitionChart
                id="currentDataType"
                yearScoreList={data?.rapidAccelerationAllInfo.allScoreList}
                createMonthly={createAllFiltered}
                showLabel={false}
                showSplitLineX={false}
                minNumber={
                  data?.rapidAccelerationAllInfo.rapidAccelerationScale
                    .minNumber
                }
                maxNumber={
                  data?.rapidAccelerationAllInfo.rapidAccelerationScale
                    .maxNumber
                }
                axisLabelIntervalY={
                  data?.rapidAccelerationAllInfo.rapidAccelerationScale
                    .YaxisLabelInterval
                }
                axisLabelUnit="回"
                gridLeft="14%"
                seriesType="line"
                axisLabelInterval={0}
              />
            </div>
          )}
          <div className="changeBtns u-margin-top-sm u-margin-bottom-xs">
            {changeBtnsList.map((item, index) => (
              <div
                className={`btns-item ${
                  item.flag == currentDataType ? 'isActive' : ''
                }`}
                key={index}
                onClick={() => handleToggle(item.flag)}
              >
                <div className="u-font-size-lg">{item.label}</div>
                <div>{item.subLabel}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="info-card info-card-elevated u-margin-bottom-md">
          <h2 className="u-font-size-xl u-padding-bottom-xs">
            朝昼夜の傾向（全期間/運転毎の平均）
          </h2>
          <BrakesProcess
            progressWidth={data?.progressWidth.morningProgress}
            label="朝"
            data={data?.rapidAccelerationInfo.morningCounts}
          />
          <BrakesProcess
            progressWidth={data?.progressWidth.afternoonProgress}
            label="昼"
            data={data?.rapidAccelerationInfo.afternoonCounts}
          />
          <BrakesProcess
            progressWidth={data?.progressWidth.eveningProgress}
            label="夜"
            data={data?.rapidAccelerationInfo.eveningCounts}
          />
          <div className="desc-bar u-margin-top-sm">
            <div>朝:5:00~11:00</div>
            <div>昼:11:00~18:00</div>
            <div>夜:18:00~翌5:00</div>
          </div>
        </div>
      </Block>

      {!isLoading && isNoData && (
        <Block>
          <div className="no-data u-text-center u-font-size-xxl">No Data</div>
        </Block>
      )}
      {isLoading && (
        <Block>
          <Card
            className="skeleton-text"
            title="Card Header"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum. Cras consequat felis at consequat hendrerit."
            footer="Card Footer"
          />
        </Block>
      )}
    </PageTemplate>
  );
};
