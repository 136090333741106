import { FC } from 'react';
import { DisplayWarrantyItem } from '../WarrantyManagementHelper';
import styles from './WarrantyUnderContractStyle.module.css';
import { InfoCard } from '@/components/uiParts/InfoCard/InfoCard';
import { List } from '@/components/uiParts/List/List';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { WARRANTY_STATUS } from '@/consts/warranty';

interface WarrantyUnderContractProps {
  warranty: DisplayWarrantyItem;
  isCancelled: boolean;
  isWarrantiesDuplicated: boolean;
  isUnderChanging: boolean;
  isAutoRenew: boolean;
  handleClickOpenDetail: () => void;
  handleClickOpenCancel: () => void;
  warrantyStatus?: number;
}

export const WarrantyUnderContract: FC<WarrantyUnderContractProps> = ({
  warranty,
  isCancelled,
  isWarrantiesDuplicated,
  isUnderChanging,
  isAutoRenew,
  handleClickOpenDetail,
  handleClickOpenCancel,
  warrantyStatus,
}) => {
  const warrantyStatusExpired = warrantyStatus === WARRANTY_STATUS.EXPIRED;

  const endDateValue = (endDate: string, updateMonthAgo: number) => (
    <div>
      <p>{endDate}</p>
      <div className={styles['list-supplement']}>
        {warranty.isWarrantyStarted &&
          !isCancelled &&
          !isUnderChanging &&
          !warrantyStatusExpired &&
          isAutoRenew && (
            <>
              {/* <p>契約満期が近づいています</p> */}
              <p>更新の{updateMonthAgo}ヶ月前です</p>
              <p>変更がない場合は、同じプランで自動更新されます</p>
            </>
          )}
      </div>
    </div>
  );

  const list = [
    {
      label: '保証開始日',
      value: warranty.warrantyStartDate,
    },
    {
      label: '保証終了日',
      value: endDateValue(warranty.warrantyEndDate, warranty.updateMonthAgo),
    },
  ];

  return (
    <RoundSheet>
      <div className={styles.card}>
        <div className={styles['card-head']}>
          <h3>{warranty.warrantyName}</h3>
          <a onClick={handleClickOpenDetail} className={styles.link}>
            <h3 className={styles.inline}>詳しくみる</h3>
          </a>
          <i className="icon-right" />
        </div>
        <br />
        <div>
          {isCancelled && (
            <InfoCard
              className="warning-card"
              bgColor="warning"
              text="保証の解約手続き済みです"
              textSub={`保証終了日： ${warranty.warrantyEndDate}`}
              iconType="confirm-notification"
              isIconRight
              onClick={handleClickOpenCancel}
            />
          )}
          {!warranty.isWarrantyStarted && (
            <InfoCard
              bgColor="warning"
              text="適用開始前の保証です"
              textSub={`変更適用日：${warranty.warrantyStartDate}`}
              iconType="confirm-notification"
            />
          )}
          {warrantyStatusExpired && (
            <InfoCard
              className="warning-card"
              bgColor="warning"
              text="保証が失効しています"
              iconType="confirm-notification"
            />
          )}
          <List
            labelWidth="150px"
            list={list}
            listClass={styles['list-class']}
          />
        </div>
      </div>
    </RoundSheet>
  );
};
