import { baseApi } from '@/config/axios';
import {
  CancelWarrantyParams,
  CancelWarrantyResponse,
  GetGuaranteeUpdateTargetParams,
  GetGuaranteeUpdateTargetResponse,
  PaymentMethodChangeParams,
  PaymentMethodChangeResponse,
  PutGuaranteeUpdateTargetParams,
  PutGuaranteeUpdateTargetResponse,
  StopUpdateNextYearParams,
  StopUpdateNextYearResponse,
  UpdateWarrantyCustomerParams,
  UpdateWarrantyCustomerResponse,
} from '@/types/api/warrantyApi';

export const cancelWarryantyApi = (
  params: CancelWarrantyParams,
): Promise<CancelWarrantyResponse> =>
  baseApi.put('/v1/customer/stop-update-next-year', { params });

export const updateWarrantyCustomerApi = (
  params: UpdateWarrantyCustomerParams,
): Promise<UpdateWarrantyCustomerResponse> =>
  baseApi.put('/v1/customer', { params });

export const getGuaranteeUpdateTargetApi = (
  params: GetGuaranteeUpdateTargetParams,
): Promise<GetGuaranteeUpdateTargetResponse> =>
  baseApi.get('/v1/customer/guarantee-update-target', { params });

//決済方法変更
export const paymentMethodChangeApi = (
  params: PaymentMethodChangeParams,
): Promise<PaymentMethodChangeResponse> =>
  baseApi.put('v1/customer/payment_method_change', params);

export const stopUpdateNextYear = (
  params: StopUpdateNextYearParams,
): Promise<StopUpdateNextYearResponse> =>
  baseApi.put('/v1/customer/stop-update-next-year', params);

export const putGuaranteeUpdateTargetApi = (
  params: PutGuaranteeUpdateTargetParams,
): Promise<PutGuaranteeUpdateTargetResponse> =>
  baseApi.put('/v1/customer/guarantee-update-target', params);
