import React, { FC } from 'react';
import style from './WarrantyCardStyle.module.css';
import iconFlower from '@/assets/images/menu/icon-flower.svg';

interface WarrantyCardProps {
  warrantyCardInfo: {
    warrantyName: string;
    warrantyNo: string;
    affiliateName: string;
  };
}

export const WarrantyCard: FC<WarrantyCardProps> = ({ warrantyCardInfo }) => {
  const { warrantyName, warrantyNo, affiliateName } = warrantyCardInfo;
  return (
    <div className={style.container}>
      <div className={style.imageContainer}>
        <div className={style.imageRowWrapper}>
          <div className={style.imageWrapper}>
            <img src={iconFlower} />
          </div>
          <div className={style.imageWrapper}>
            <img className={style.imageTopRight} src={iconFlower} />
          </div>
        </div>
        <div className={style.imageRowWrapper}>
          <div className={style.imageWrapper}>
            <img className={style.imageBottomLeft} src={iconFlower} />
          </div>
          <div className={style.imageWrapper}>
            <img className={style.imageBottomRight} src={iconFlower} />
          </div>
        </div>
      </div>
      <div className={style.borderBold}>
        <div className={style.borderThin}>
          <div className={style.content}>
            <div className={style.title}>
              <span>★</span>
              <span>保証書</span>
              <span>★</span>
            </div>
            <div className={style.warrantyNameContainer}>
              <p className={style.warrantyName}>{warrantyName}</p>
            </div>
            <div className={style.warrantyNoContainer}>
              <p className={style.warrantyNo}>{warrantyNo}</p>
            </div>
            <div className={style.affiliateNameContainer}>
              <p className={style.affiliateName}>{affiliateName}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
