import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useCallback, useEffect, useState } from 'react';
import { WarrantyManagement } from './WarrantyManagement/WarrantyManagement';
import {
  findRelevantNextWarranty,
  findWarrantyPaymentMethod,
  getCarInfoTiredToTheWarranty,
  getValidWarranties,
  selectDisplayPayment,
} from './WarrantyManagementHelper';
import { AuthInfoState } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { deliveryCompleteFlg } from '@/consts/car';
import { usePaymentList } from '@/hooks/api/payment/usePaymentList';
import { usePaymentMethodChange } from '@/hooks/api/warranty/usePaymentMethodChange';
import { useWarrantyManagement } from '@/hooks/api/warranty/useWarrantyManagement';
import { useLoadCustomer } from '@/hooks/useLoadCustomer';
import { Car, PaymentMethod } from '@/types/api/customerApi';
import { storeDispatch } from '@/utils/store';
import { f7CustomBack } from '@/utils/utils';
import { findAppendWarrantyOfTypeOne } from '@/utils/warranty';

type WarrantyManagementPageProps = {
  f7router: Router.Router;
  status?: string;
  event?: string;
  // vue版ではendTypeというpropsを受け取るようになっているが使用されていないため記述しない
};

export const WarrantyManagementPage: FC<WarrantyManagementPageProps> = ({
  f7router,
  status = '',
  event = '',
}) => {
  const m_car_id =
    f7router.currentRoute.query?.car || window.localStorage.getItem('m_car_id');
  const t_order_id =
    f7router.currentRoute.query?.car ||
    window.localStorage.getItem('t_order_id');

  // store
  const authInfo = useStore(store, 'authInfo') as AuthInfoState;
  const mCustomerId = authInfo.m_customer_id;
  const storeCurrentWarranty = useStore(store, 'getCurrentWarranty');
  const storeNextWarranty = useStore(store, 'getNextWarranty');
  const storeBills = useStore(store, 'getBillInfo');
  const storePaymentMethods = useStore(store, 'getPaymentMethodsInfo') as
    | PaymentMethod[]
    | undefined;
  const storeCarItemInfo = useStore(store, 'carItemInfo') as
    | Required<Car>
    | undefined;
  const storeWarranties = useStore(store, 'getWarranties');
  const storeNextWarranties = useStore(store, 'getNextWarranties');
  const carsInfo = useStore(store, 'carsInfo') as Required<Car>[];

  // hooks
  const [isUpdating, setIsUpdating] = useState(false);
  const [car, setCar] = useState<Required<Car> | null>(null);
  const {
    isLoading,
    warranties,
    nextWarranties,
    currentWarranty,
    nextWarranty,
    bills,
    paymentMethods,
    callCustomerInfoApi,
    updateUserData,
  } = useWarrantyManagement(
    mCustomerId,
    storeWarranties,
    storeNextWarranties,
    storeCurrentWarranty,
    storeNextWarranty,
    storeBills,
    storePaymentMethods,
    storeCarItemInfo && {
      ...storeCarItemInfo,
      m_car_id: Number(m_car_id || 0),
      t_order_id: Number(t_order_id || 0),
    },
  );
  const { changePaymentMethod } = usePaymentMethodChange();
  // 定数
  const [warrantyNo, setWarrantyNo] = useState(
    currentWarranty?.warranty_no || '',
  );
  const [appendWarranty, setAppendWarranty] = useState(
    findAppendWarrantyOfTypeOne(currentWarranty),
  );
  const [carInfoTiredToTheWarranty, setCarInfoTiredToTheWarranty] = useState(
    getCarInfoTiredToTheWarranty(
      warrantyNo || '',
      appendWarranty,
      nextWarranty,
      bills,
    ),
  );
  const warrantyPaymentMethod = findWarrantyPaymentMethod(
    currentWarranty,
    paymentMethods,
  );
  const [displayPayment, setDisplayPayment] = useState(
    selectDisplayPayment(
      carInfoTiredToTheWarranty.billInfo.currentBillInfo,
      carInfoTiredToTheWarranty.billInfo.nextBillInfo,
      warrantyPaymentMethod,
    ),
  );
  const { updatePaymentList, isFetched, isFetching } = usePaymentList();
  const customerId = window.localStorage.getItem('m_customer_id');
  const token =
    window.localStorage.getItem('token') === null
      ? undefined
      : window.localStorage.getItem('token');
  const { isLoggedIn } = useLoadCustomer(
    f7.views.main.router,
    token === null ? undefined : token,
    Number(customerId),
    status,
  );

  // 関数
  // ページを再レンダリングさせたい際に、別画面で呼び出す用の関数
  const handleIsUpdating = () => {
    setIsUpdating(true);
  };

  const handleClickBack = () => {
    if (
      status === 'update_success' ||
      status === 'gmo_success' ||
      status === 'gmo_cancel' ||
      status === 'warranty_detail'
    ) {
      f7.view.main.router.navigate('/home/', { transition: 'push-reverse' });
    } else {
      f7CustomBack();
    }
  };

  const handleClickChangePlan = () => {
    // TODO: vue版に合わせる場合はwarrantiesをストアから取得する
    // warrantiesの更新が頻繁に行われる場合はreactもストアに格納した方いいか
    storeDispatch('setPaymentTempData', 'paymentTempData', {
      type: 'warranty',
      openDialogName: `/update_warranty/${warrantyNo}/`,
      step: 5,
      isTransition: false,
    });
    // 保証プラン変更step１へ遷移
    f7.view.main.router.navigate(paths.updateWarranty(warrantyNo));
  };

  const handleClickChangeSetElementMethod = () => {
    const currentCarId = f7router.currentRoute.query?.car;
    store.dispatch('setPaymentTempData', {
      type: 'warranty',
      openDialogName: paths.registerPayment('warranty', warrantyNo),
      isTransition: false,
    });
    f7.view.main.router.navigate(
      paths.registerPayment('warranty', warrantyNo),
      {
        props: {
          redirectPage: 'warranty',
          nextBill: carInfoTiredToTheWarranty.billInfo.nextBillInfo,
          event: handleIsUpdating,
          currentCarId,
        },
      },
    );
  };

  const afterGmoRedirect = useCallback(async () => {
    const currentCarId = window.localStorage.getItem('currentCarId');
    if (!isLoggedIn || warrantyNo === '') return;
    window.localStorage.removeItem('prepaid_registration_info');
    switch (status) {
      // GMOペイメント post →　maikuru バックエンド（api.mobicon.net）からのリダイレクト
      case 'gmo_success':
        if (!isFetched && !isFetching) {
          updatePaymentList(Number(authInfo.m_customer_id));
          setTimeout(() => {
            f7.views.main.router.navigate(
              paths.registerPayment('warranty', warrantyNo),
              {
                props: {
                  redirectPage: 'warranty',
                  nextBill: carInfoTiredToTheWarranty.billInfo.nextBillInfo,
                  event: handleIsUpdating,
                  currentCarId,
                },
              },
            );
          }, 500);
          return;
        }
        break;
      case 'gmo_cancel':
        if (!isFetched && !isFetching) {
          updatePaymentList(Number(authInfo.m_customer_id));
          setTimeout(() => {
            f7.views.main.router.navigate(
              paths.registerPayment('warranty', warrantyNo),
              {
                props: {
                  redirectPage: 'warranty',
                  nextBill: carInfoTiredToTheWarranty.billInfo.nextBillInfo,
                  event: handleIsUpdating,
                  currentCarId,
                },
              },
            );
          }, 500);
          return;
        }
        break;
      default:
        return;
    }
  }, [
    isLoggedIn,
    warrantyNo,
    status,
    isFetched,
    isFetching,
    updatePaymentList,
  ]);

  useEffect(() => {
    afterGmoRedirect();
  }, [afterGmoRedirect]);

  useEffect(() => {
    const fn = async () => {
      if (isLoggedIn && isUpdating && !isLoading) {
        updateUserData();
        setIsUpdating(false);
      }
    };
    fn();
  }, [isLoggedIn, callCustomerInfoApi, isUpdating, isLoading]);

  useEffect(() => {
    if (!isUpdating && !currentWarranty) setIsUpdating(true);
  }, [currentWarranty, isUpdating]);

  useEffect(() => {
    const currentCarId =
      f7router.currentRoute.query?.car ||
      window.localStorage.getItem('currentCarId') ||
      storeCarItemInfo?.m_car_id;
    if (currentCarId) {
      const currentCar = carsInfo.find(
        (i) => i.m_car_id === Number(currentCarId),
      );
      if (currentCar) {
        let validWarranties = getValidWarranties(currentCar.warranties);
        if (
          currentCar.delivery_complete_flg ==
            deliveryCompleteFlg.BEFORE_DELIVERY &&
          validWarranties.length === 0
        ) {
          validWarranties = warranties;
        }
        const warNo = validWarranties[0]?.warranty_no;
        const appendWar = findAppendWarrantyOfTypeOne(validWarranties[0]);
        const carInfoTiredToTheWar = getCarInfoTiredToTheWarranty(
          warNo || '',
          appendWar,
          findRelevantNextWarranty(
            validWarranties[0],
            currentCar.next_warranty,
          ),
          bills,
        );

        const displayPaymentDetail = selectDisplayPayment(
          carInfoTiredToTheWar.billInfo.currentBillInfo,
          carInfoTiredToTheWar.billInfo.nextBillInfo,
          findWarrantyPaymentMethod(validWarranties[0], storePaymentMethods),
        );
        const paymentMethodStorage =
          window.localStorage.getItem('payment_methods');
        if (
          !displayPaymentDetail &&
          paymentMethodStorage &&
          storePaymentMethods &&
          warNo
        ) {
          window.localStorage.removeItem('payment_methods');
          const storagePayments = JSON.parse(
            paymentMethodStorage,
          ) as Partial<PaymentMethod>[];
          const newPayments = storePaymentMethods.filter(
            (payment) =>
              !storagePayments.find(
                (method) =>
                  method.m_payment_method_id === payment.m_payment_method_id &&
                  method.m_payment_type_id === payment.m_payment_type_id,
              ),
          );
          const newPayment = newPayments.find(
            (payment) =>
              payment.m_payment_type_id && payment.m_payment_method_id,
          );
          if (newPayment) {
            const params = {
              m_customer_id: mCustomerId,
              bill_kind: 1,
              m_payment_type_id: newPayment.m_payment_type_id || 0,
              m_payment_method_id: newPayment.m_payment_method_id || 0,
              warranty_no: warNo,
              branch_no: validWarranties[0]?.branch_no,
            };
            changePaymentMethod(params).then(() => callCustomerInfoApi());
          }
        }

        setCar(currentCar);
        setWarrantyNo(warNo);
        setAppendWarranty(appendWar);
        setCarInfoTiredToTheWarranty(carInfoTiredToTheWar);
        setDisplayPayment(displayPaymentDetail);
      }
    }
  }, [carsInfo, f7router]);

  return (
    <WarrantyManagement
      f7router={f7router}
      warranties={car ? car.warranties || [] : warranties}
      nextWarranties={car ? car.next_warranty : nextWarranties}
      deliveryFlg={
        car
          ? car.delivery_complete_flg
          : storeCarItemInfo?.delivery_complete_flg || ''
      }
      carInfoTiredToTheWarranty={carInfoTiredToTheWarranty}
      warrantyNo={warrantyNo}
      nextBaseWarrantyId={nextWarranty?.t_base_waranty_id}
      appendWarranty={appendWarranty}
      displayPayment={displayPayment}
      handleClickBack={handleClickBack}
      handleClickChangePlan={handleClickChangePlan}
      handleClickChangeSetElementMethod={handleClickChangeSetElementMethod}
    />
  );
};
