import {
  applyStatus as APPLY_STATUS,
  WARRANTY_STATUS,
  warrantyCoverageType,
  warrantyType,
} from '@/consts/warranty';
import {
  Car,
  MWarrantyPart,
  NextWarranty,
  NextWarrantyAppendWarranty,
  WarrantiesAppendWarranty,
  Warranty,
} from '@/types/api/customerApi';
import { ApplyStatus } from '@/types/warranty';

import { WarrantyCoverageType } from '@/types/warranty';

export type WarrantyPartsType = WarrantyPart[];

export type WarrantyPart = {
  m_accessory_category_name: string;
  subItems: MWarrantyPart[];
};

export type WarrantyGroup = {
  [index: string]: MWarrantyPart[];
};

export const getWarrantyPartsData = (warrantyList: MWarrantyPart[]) => {
  const groups: WarrantyGroup = {};
  warrantyList.forEach((el) => {
    const group = el['parts_category_name'] ?? '';
    groups[group] = groups[group] || [];
    groups[group].push(el);
  });
  return Object.keys(groups).map((group) => {
    const data: WarrantyPart = {
      m_accessory_category_name: group,
      subItems: groups[group],
    };
    return data;
  });
};

export const hasWarrantyTiredToCar = (car: Car | undefined) => {
  return !!car?.warranties?.[0]?.warranty_no;
};

export const findAppendWarrantyOfTypeOne = (warranty: Warranty | undefined) => {
  return warranty?.append_warranties?.find(
    (w: WarrantiesAppendWarranty) => w.warranty_type === warrantyType.TYPE_ONE,
  );
};

export const findNextAppendWarrantyOfTypeOne = (
  warranty: NextWarranty | undefined,
) => {
  return warranty?.append_warranties?.find(
    (w: NextWarrantyAppendWarranty) =>
      w.warranty_type === warrantyType.TYPE_ONE,
  );
};

export const switchWarrantyPartsTitleByCoverageType = (
  type: WarrantyCoverageType | undefined | null,
) => {
  switch (type) {
    case warrantyCoverageType.COVERED:
      return '保証対象外部位';
    case warrantyCoverageType.NOT_COVERED:
      return '保証対象部位';
    default:
      return '保証対象部位';
  }
};

export const isCancellationInProcess = (
  applyStatus: ApplyStatus | undefined,
  warrantyStatus: number | undefined,
) => {
  return (
    warrantyStatus === WARRANTY_STATUS.VALID &&
    (applyStatus === APPLY_STATUS.DURING_CANCELLATION_APPLICATION ||
      applyStatus === APPLY_STATUS.OUTSIDE_CANCELLATION_APPLICATION)
  );
};
