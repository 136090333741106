import { addDays } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Checkbox,
  PairInput,
  PhoneNumberInput,
  Radio,
  SelectCalendar,
  TimePicker,
} from 'src/components/pages/CarRepair/Partials';
import { makeDisplayWarrantyDetail } from '../../WarrantyDetail/WarrantyDetailHelper';
import styles from './CarRepairRequestToStore.module.scss';
import { PopupConfirm } from '@/components/projects/CarRepair/PopupConfirm';
import { Button } from '@/components/uiParts/Button';
import { store } from '@/config/store';
import {
  CHECK_EXPECTED_ATS,
  CheckExpectedAt,
  IDENTIFICATION_SUBSCRIBERS,
  IdentificationSubscriber,
  MIN_REPAIR_AFTER_CURRENT_DAY,
} from '@/consts/carRepair';
import { CarRepairFormData } from '@/hooks/api/carRepair/useCarRepair';
import { findAppendWarrantyOfTypeOne } from '@/utils/warranty';

interface CarRepairRequestToStoreProps {
  callCenterTel?: string;
  onSubmit: () => void;
}

export const CarRepairRequestToStore: FC<CarRepairRequestToStoreProps> = ({
  callCenterTel,
  onSubmit,
}) => {
  const { customerInfo, currentWarranty } = store.state;
  const warranty = findAppendWarrantyOfTypeOne(currentWarranty);

  const displayWarrantyDetail = makeDisplayWarrantyDetail(
    customerInfo.affiliate_name,
    warranty,
  );
  const { InHouseDeductible, otherCompanyDeductible } = displayWarrantyDetail;

  const [opened, setOpened] = useState(false);
  const { watch, formState, trigger } = useFormContext<CarRepairFormData>();
  const { errors } = formState;
  const minDate = addDays(new Date(), MIN_REPAIR_AFTER_CURRENT_DAY);
  const checkExpected = watch('is_checked_expected_nyukosaki');
  const date1 = watch('expected_nyuko_date1');
  const time1 = watch('expected_nyuko_time1');

  const identificationSubscriber = watch(
    'is_checked_identification_subscriber',
  );
  const isExternalFactory = checkExpected == CheckExpectedAt.EXTERNAL_FACTORY;
  const handleOpenPopupConfirm = (value: string) => {
    value === CheckExpectedAt.EXTERNAL_FACTORY &&
      !isExternalFactory &&
      setOpened(true);
  };
  useEffect(() => {
    document.querySelector('.page-content > div > div')?.scrollTo(0, 0);
  }, []);

  const triggerDateTimeField = () => {
    trigger('expected_nyuko_date1');
    trigger('expected_nyuko_date2');
    trigger('expected_nyuko_date3');

    trigger('expected_nyuko_time1');
    trigger('expected_nyuko_time2');
    trigger('expected_nyuko_time3');
  };

  return (
    <div className={styles['container']}>
      <PopupConfirm
        opened={opened}
        onPopupClose={() => setOpened(false)}
        callCenterTel={callCenterTel}
      />
      <div className={`${styles['input-group']} ${styles['no-padding-top']}`}>
        <h3 className={styles['input-group-label']}>
          免責金についてご確認ください
          <span
            className={`${styles['input-group-label-tips']} ${styles['required']}`}
          >
            必須
          </span>
        </h3>
        <div className={`${styles['note-field']} ${styles['note-fee']}`}>
          <div className="display-flex-sb">
            <p className={styles['note-label']}>販売店入庫：</p>
            <p className={styles['note-value']}>
              {Number(InHouseDeductible).toLocaleString()}円
            </p>
          </div>
          <div className="display-flex-sb">
            <p className={styles['note-label']}>他社様への入庫：</p>
            <p className={styles['note-value']}>
              {Number(otherCompanyDeductible).toLocaleString()}円
            </p>
          </div>
          <div className={styles['note-description']}>
            <span>※</span>
            一度の修理受付につき、「修理事務手数料」として
          </div>
        </div>
        <Checkbox name="feeConfirmed">上記の内容を確認しました</Checkbox>
      </div>
      <div className={styles['input-group']}>
        <h3 className={styles['input-group-label']}>
          修理する車の入庫先を選択してください
          <span
            className={`${styles['input-group-label-tips']} ${styles['required']}`}
          >
            必須
          </span>
        </h3>
        <div className={styles['checkbox-radio-items']}>
          {CHECK_EXPECTED_ATS.map((item) => (
            <Radio
              key={`checkExpected-${item.value}`}
              id={`checkExpected__${item.value}`}
              name="is_checked_expected_nyukosaki"
              checked={checkExpected == item.value}
              value={item.value}
              className={styles['input-radio']}
              handleOnClick={handleOpenPopupConfirm}
            >
              {item.text}
            </Radio>
          ))}
        </div>
      </div>
      <div
        className={`${styles['input-group']} ${
          checkExpected != CheckExpectedAt.EXTERNAL_FACTORY
            ? styles['hidden']
            : ''
        }`}
      >
        <h3 className={styles['input-group-label']}>
          ご入庫の際、工場の担当者へ以下の3点を点検開始前にお話しください。
          <span
            className={`${styles['input-group-label-tips']} ${styles['required']}`}
          >
            必須
          </span>
        </h3>
        <div className={`${styles['note-field']} ${styles['mt-2']}`}>
          <p className={styles['number-bullet']}>
            自動車保証に加入しておりその保証を利用しての修理を希望すること
          </p>
          <p className={styles['number-bullet']}>
            工場担当者からサポートセンターへご連絡を入れていただく必要があること
          </p>
          <p className={styles['number-bullet']}>
            当サポートセンターの電話番号 {callCenterTel && `(${callCenterTel})`}
          </p>
        </div>
        <Checkbox name="warrantyConfirmed">上記の内容を確認しました</Checkbox>
      </div>
      <div
        className={`${styles['input-group']} ${
          isExternalFactory ? styles['hidden'] : ''
        }`}
      >
        <h3 className={styles['input-group-label']}>
          ご予約の希望日時を選択してください
        </h3>
        <h3 className={styles['input-group-label']}>
          第一希望
          <span
            className={`${styles['input-group-label-tips']} ${styles['required']}`}
          >
            必須
          </span>
        </h3>
        <SelectCalendar
          label="日付"
          placeholder="年/月/日"
          name="expected_nyuko_date1"
          minDate={minDate}
          onChange={() => triggerDateTimeField()}
        />
        <TimePicker
          label="開始時間"
          id="expected_nyuko_time1"
          name="expected_nyuko_time1"
          onChange={() => triggerDateTimeField()}
          hasError={
            (!!errors?.expected_nyuko_date1 ||
              !!errors?.expected_nyuko_time1) &&
            !!date1 &&
            !!time1
          }
        />
        {(!!errors?.expected_nyuko_date1 || !!errors?.expected_nyuko_time1) &&
          !!date1 &&
          !!time1 && (
            <p className={styles['error-message']}>
              選択した時間が重複しています
            </p>
          )}
        <h3
          className={`${styles['input-group-label']} ${styles['margin-top-2']}`}
        >
          第二希望
          <span className={styles['input-group-label-tips']}>任意</span>
        </h3>
        <SelectCalendar
          label="日付"
          placeholder="年/月/日"
          name="expected_nyuko_date2"
          minDate={minDate}
          onChange={() => triggerDateTimeField()}
          timePairName="expected_nyuko_time2"
          showClearButton
          hasError={
            ['required', 'unique'].includes(
              errors?.expected_nyuko_date2?.type as string,
            ) || errors?.expected_nyuko_time2?.type == 'unique'
          }
        />
        {errors?.expected_nyuko_date2?.type == 'required' && (
          <p className={styles['error-message']}>
            {errors.expected_nyuko_date2?.message}
          </p>
        )}
        <TimePicker
          label="開始時間"
          id="expected_nyuko_time2"
          name="expected_nyuko_time2"
          onChange={() => triggerDateTimeField()}
          hasError={
            ['required', 'unique'].includes(
              errors?.expected_nyuko_time2?.type as string,
            ) || errors?.expected_nyuko_date2?.type == 'unique'
          }
        />
        {(['required', 'unique'].includes(
          errors?.expected_nyuko_time2?.type as string,
        ) ||
          errors?.expected_nyuko_date2?.type == 'unique') && (
          <p className={styles['error-message']}>
            {errors?.expected_nyuko_time2?.message ||
              errors?.expected_nyuko_date2?.message}
          </p>
        )}
        <h3
          className={`${styles['input-group-label']} ${styles['margin-top-2']}`}
        >
          第三希望
          <span className={styles['input-group-label-tips']}>任意</span>
        </h3>
        <SelectCalendar
          label="日付"
          placeholder="年/月/日"
          name="expected_nyuko_date3"
          minDate={minDate}
          onChange={() => triggerDateTimeField()}
          timePairName="expected_nyuko_time3"
          showClearButton
          hasError={
            ['required', 'unique'].includes(
              errors?.expected_nyuko_date3?.type as string,
            ) || errors?.expected_nyuko_time3?.type == 'unique'
          }
        />
        {errors?.expected_nyuko_date3?.type == 'required' && (
          <p className={styles['error-message']}>
            {errors.expected_nyuko_date3?.message}
          </p>
        )}
        <TimePicker
          label="開始時間"
          id="expected_nyuko_time3"
          name="expected_nyuko_time3"
          onChange={() => triggerDateTimeField()}
          hasError={
            ['required', 'unique'].includes(
              errors?.expected_nyuko_time3?.type as string,
            ) || errors?.expected_nyuko_date3?.type == 'unique'
          }
        />
        {(['required', 'unique'].includes(
          errors?.expected_nyuko_time3?.type as string,
        ) ||
          errors?.expected_nyuko_date3?.type == 'unique') && (
          <p className={styles['error-message']}>
            {errors?.expected_nyuko_time3?.message ||
              errors?.expected_nyuko_date3?.message}
          </p>
        )}
      </div>
      <div className={styles['input-group']}>
        <h3 className={styles['input-group-label']}>
          ご連絡先の電話番号
          <span
            className={`${styles['input-group-label-tips']} ${styles['required']}`}
          >
            必須
          </span>
        </h3>
        <PhoneNumberInput
          name="contact_person_phone_number"
          hasError={!!errors?.contact_person_phone_number}
        />
        {!!errors?.contact_person_phone_number && (
          <p className={styles['error-message']}>
            有効な電話番号が指定されていません
          </p>
        )}
      </div>
      <div
        className={`${styles['input-group']} ${
          identificationSubscriber == IdentificationSubscriber.MYSELF
            ? styles['no-border']
            : ''
        }`}
      >
        <h3 className={styles['input-group-label']}>
          ご連絡先電話番号と契約者様の関係
          <span
            className={`${styles['input-group-label-tips']} ${styles['required']}`}
          >
            必須
          </span>
        </h3>
        <div className={styles['checkbox-radio-items']}>
          {IDENTIFICATION_SUBSCRIBERS.map((item) => (
            <Radio
              key={`is_checked_identification_subscriber-${item.value}`}
              id={`is_checked_identification_subscriber${item.value}`}
              name="is_checked_identification_subscriber"
              checked={identificationSubscriber === item.value}
              value={item.value}
              className={styles['input-radio']}
            >
              {item.text}
            </Radio>
          ))}
        </div>
      </div>
      {identificationSubscriber == IdentificationSubscriber.AGENT && (
        <>
          <div className={`${styles['input-group']} ${styles['no-border']}`}>
            <h3 className={`${styles['input-group-label']} ${styles['mb-1']}`}>
              ご連絡先の氏名
              <span
                className={`${styles['input-group-label-tips']} ${styles['required']}`}
              >
                必須
              </span>
            </h3>
            <PairInput
              label="お名前"
              firstInputName="contact_person_name.family_name"
              secondInputName="contact_person_name.first_name"
              firstPlaceholder="田中"
              secondPlaceholder="太郎"
              firstError={
                errors?.contact_person_name?.family_name?.type === 'required'
                  ? 'お名前が指定されていません'
                  : ''
              }
              secondError={
                errors?.contact_person_name?.first_name?.type === 'required'
                  ? 'お名前が指定されていません'
                  : ''
              }
            />
            <PairInput
              label="フリガナ"
              firstInputName="contact_person_name_kana.family_name"
              secondInputName="contact_person_name_kana.first_name"
              firstPlaceholder="タナカ"
              secondPlaceholder="タロウ"
              firstError={
                errors?.contact_person_name_kana?.family_name?.message
              }
              secondError={
                errors?.contact_person_name_kana?.first_name?.message
              }
            />
          </div>
        </>
      )}
      <Button
        disabled={!formState.isValid}
        type="button"
        className={styles['submit-button']}
        text={isExternalFactory ? '依頼内容を確認する' : '内容を確認する'}
        onClick={onSubmit}
      />
    </div>
  );
};
