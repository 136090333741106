/* eslint-disable no-magic-numbers */
import { secondsInHour, secondsInMinute } from 'date-fns';
import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { isIPhoneApp } from '@/config/device';
import { url } from '@/consts/url';

//小数一桁残したkmに直す関数
export const meterTokm = (meters: number) => {
  const km = (meters / 1000).toFixed(1);
  return km;
};

export const isEmpty = (obj: Object) => {
  return !Object.keys(obj).length;
};

// 脳トレ・テレマーレージの認証用hash生成
export const createHashString = async (
  text: string,
  upperCase: boolean = true,
) => {
  // バイトストリームを格納
  const uint8 = new TextEncoder().encode(text);
  // sha256でバイトストリームを暗号化
  const digest = await crypto.subtle.digest('SHA-256', uint8);
  // 型付配列をArrayインスタンスに変換
  const ret = Array.from(new Uint8Array(digest))
    // 数値を16進数の文字列に変換
    // 一桁の場合は先頭に0
    .map((v) => v.toString(16).padStart(2, '0'))
    .join('');
  return upperCase ? ret.toUpperCase() : ret;
};

export const getArrayLast = <T>(arr: T[]): T | undefined => {
  return arr.slice(-1)[0];
};

export const getKeyByValue = (
  object: Record<string, unknown>,
  value: unknown,
) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const handleClickLink = (paths: string, f7router: Router.Router) => {
  f7.view.main.router.navigate(paths);
};

export const sleep = (second: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, second * 1000);
  });
};

export const isEmptyObject = (obj: Object | undefined) => {
  if (!obj) return false;
  return !Object.keys(obj).length;
};

export const snakeToCamel = (obj: Record<string, any>): Record<string, any> => {
  const newObj: Record<string, any> = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = key.replace(/_([a-z])/g, (match, p1) => p1.toUpperCase());
      if (obj[key] == null) {
        newObj[newKey] = undefined;
        continue;
      }
      newObj[newKey] =
        typeof obj[key] === 'object' ? snakeToCamel(obj[key]) : obj[key];
    }
  }
  return newObj;
};

// 車両画像のパスを生成
export const getCarImageSrc = (path?: string) => {
  return path ? url.MOBICON_API + window.btoa(path) : url.MOBICON_API;
};

export const getCarPhoto = (
  car?: Partial<{
    user_upload_image_file_path?: string | null;
    file_path?: string | null;
  }>,
  withDefault = true,
) =>
  car?.user_upload_image_file_path ||
  (car?.file_path
    ? getCarImageSrc(car?.file_path)
    : withDefault
    ? getCarImageSrc('')
    : '');

export const getNewsImage = (path?: string) => {
  return path
    ? url.NEWS_RESOURCE_URL + window.btoa(path)
    : url.NEWS_RESOURCE_URL;
};

// Get Hour, Minutes, or Seconds from seconds (HMS stands for HOUR, MINUTE, SECOND)
export const getHMS = (seconds: number, type: HMS) => {
  switch (type) {
    case 'hour':
      return Math.floor(seconds / secondsInHour);
    case 'minute':
      return Math.floor((seconds % secondsInHour) / secondsInMinute);
    case 'second':
      return Math.floor(seconds % secondsInMinute);
  }
};

// Enum for hour, minute, second for getHMS function
type HMS = 'hour' | 'minute' | 'second';

// 桁数指定したランダムな数字を生成
export const getRandomNumbericString = (length: number) => {
  const charecters = '0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    result += charecters[Math.floor(Math.random() * charecters.length)];
  }

  return result;
};

export const removeNonNumericLetters = (text: string): string => {
  return text.replace(/\D/g, '');
};

const f7CloseMultiplePopup = (popupIds: string[]) => {
  popupIds.forEach((popupId) => {
    f7.popup.close(popupId);
  });
  return f7.view.main.router;
};

/**
 * Use: f7CustomBack(); f7CustomBack(1); f7CustomBack(['#popupId']);
 */
export const f7CustomBack = (steps: number | string[] = 1): Router.Router => {
  // return when can not back
  if (steps === 0) {
    return f7.view.main.router;
  }

  // Option for close multiple popup id
  if (Number(steps) !== steps) {
    if (!isIPhoneApp) {
      return f7CloseMultiplePopup(steps as string[]);
    }

    steps = (steps as string[]).length;
  }

  // when is not iphone app => back with f7 back
  if (!isIPhoneApp) {
    f7.view.main.router.back();
    return f7CustomBack(steps - 1);
  }

  // back to before history: replace history if is iphone app
  const f7History = f7.view.main.router.history;
  const path = f7History?.slice()[f7History?.length - 2];
  f7.view.main.router.updateCurrentUrl(`${path}`);
  f7.view.main.router.back(path, {
    browserHistory: false,
  });

  return f7CustomBack(steps - 1);
};

export function findLastIndex<T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => boolean,
): number {
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i], i, array)) {
      return i;
    }
  }
  return -1;
}
