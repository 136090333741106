import { f7, useStore } from 'framework7-react';
import { useEffect, useState } from 'react';
import { useHouseprepaidUseInfo } from '../prepaid/useHouseprepaidUseInfo';
import { Step1FormData } from './useCarWashConditionerStep1';
import { Step2FormData } from './useCarWashConditionerStep2';
import { Step3FormData } from './useCarWashConditionerStep3';
import { customerInfoApi } from '@/api/customerApi';
import { chargePrepaidApi } from '@/api/prepaidApi';
import { createCarWash, fetchCourseInfos } from '@/api/reservationApi';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { serviceTypes, steps } from '@/consts/carWash';
import { chargeClassification, chargeType } from '@/consts/charge';
import { useLoadCustomer } from '@/hooks/useLoadCustomer';
import { useToast } from '@/hooks/useToast';
import { Customer, Prepaid } from '@/types/api/customerApi';
import {
  CourseItem,
  CreateCarWashParams,
  FetchCourseInfosParams,
  OptionItem,
} from '@/types/api/reservationApi';
import { dispatchBasicInfo } from '@/utils/store';

const SUCCESS_DISPLAY_TIME = 3000;

function parseJSON<T = any>(text: string | null): T | null {
  try {
    return JSON.parse(text || '');
  } catch {
    return null;
  }
}

export const useCarWashConditioner = (status?: string) => {
  const storeCustomerInfo = useStore(store, 'customerInfo') as Customer;
  const prepaid = useStore(store, 'getFirstAdvancePaymentsInfo') as
    | Prepaid
    | undefined;
  const { registerPrepaid } = useHouseprepaidUseInfo(
    '処理が正常に完了できませんでした。お手数ですが、再度お支払いを行なってください',
  );
  const { openToast } = useToast();
  const [prepaidCharged, setPrepaidCharged] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [step, setStep] = useState<number>(steps.PRE_SELECTION);
  const [courseItems, setCourseItems] = useState<CourseItem[]>([]);
  const [optionItems, setOptionItems] = useState<OptionItem[]>([]);
  const [courseItem, setCourseItem] = useState<CourseItem>();
  const [optionItem, setOptionItem] = useState<OptionItem[]>([]);
  const [step1Data, setStep1Data] = useState<Step1FormData>({
    car: {
      optionItemCodes: [],
      unregisteredCar: {
        useUserCar: true,
      },
    },
  });
  const [step2Data, setStep2Data] = useState<Step2FormData>({
    preferredDatetimes: {},
    reservationHolder: {},
    workspace: {},
  });
  const [step3Data, setStep3Data] = useState<Step3FormData>({
    engineStart: false,
    dateAnnotation: false,
    cancelFee: false,
    badWeather: false,
    workspace: {},
  });
  const customerId = window.localStorage.getItem('m_customer_id');
  const token =
    window.localStorage.getItem('token') === null
      ? undefined
      : window.localStorage.getItem('token');

  const { isLoggedIn, retrieveUserInfo } = useLoadCustomer(
    f7.view.main.router,
    token === null ? undefined : token,
    Number(customerId),
  );

  useEffect(() => {
    if (status) {
      setStep(steps.REVIEW);
    }
  }, [status]);

  useEffect(() => {
    if (status && storeCustomerInfo.id && !prepaidCharged) {
      setPrepaidCharged(true);
      const destination = localStorage.getItem('destination_page');
      if (status === 'gmo_success' && destination === paths.chargePrepaid) {
        const chargePrice = window.localStorage.getItem('charge_amount');
        const mPrepaidId = window.localStorage.getItem('m_prepaid_id');
        if (chargePrice) {
          const params = {
            m_customer_id: storeCustomerInfo.id,
            m_prepaid_id: Number(mPrepaidId) ?? 0,
            charge_price: Number(chargePrice),
            charge_kbn: prepaid?.fixed_deposit_flg
              ? chargeClassification.REGULAR
              : chargeClassification.BASIC,
            charge_type: chargeType.SPOT,
          };
          chargePrepaidApi(params).then((res) => {
            const error_message =
              res.data.error_message ?? '処理に失敗しました。';
            if (!res.data.success) {
              openToast(error_message, 'toast-failed', 3000, true, 'top');
              return;
            }
            retrieveUserInfo();
          });
        }
      }
    }
  }, [prepaidCharged, retrieveUserInfo, status, storeCustomerInfo]);

  useEffect(() => {
    if (!storeCustomerInfo.id || courseItems.length) return;
    f7.preloader.show();
    const params: FetchCourseInfosParams = {
      service_type: serviceTypes.CONDITIONER,
    };
    fetchCourseInfos(params)
      .then(({ data }) => {
        if (!data.success) {
          f7.dialog.alert('', data.error_message || 'エラー発生しています');
          return;
        }
        setCourseItems(data.course_items);
        setOptionItems(data.option_items);
      })
      .catch(() => {})
      .finally(() => {
        f7.preloader.hide();
      });
    const [tel1 = '', tel2 = '', tel3 = ''] =
      storeCustomerInfo.phone_no1?.split('-') || [];
    const storageStep1 = parseJSON<Step1FormData>(
      localStorage.getItem('car_wash_step_1'),
    );
    const storageStep2 = parseJSON<Step2FormData>(
      localStorage.getItem('car_wash_step_2'),
    );
    const storageStep3 = parseJSON<Step3FormData>(
      localStorage.getItem('car_wash_step_3'),
    );
    if (storageStep1 && storageStep2 && storageStep3 && status) {
      setStep1Data(storageStep1);
      setStep2Data(storageStep2);
      setStep3Data(storageStep3);
    } else if (!step2Data.preferredDatetimes.datetime1) {
      setStep2Data({
        preferredDatetimes: {},
        reservationHolder: {
          familyName: storeCustomerInfo.family_name || '',
          firstName: storeCustomerInfo.first_name || '',
          familyNameKana: storeCustomerInfo.family_name_kana || '',
          firstNameKana: storeCustomerInfo.first_name_kana || '',
          tel1,
          tel2,
          tel3,
        },
        workspace: {
          zipCode: `${storeCustomerInfo.zip_code1 || ''}-${
            storeCustomerInfo.zip_code2 || ''
          }`,
          prefectures: storeCustomerInfo.address1 || '',
          municipalities: storeCustomerInfo.address2 || '',
          street: storeCustomerInfo.address3 || '',
        },
      });
    }
    localStorage.removeItem('step');
    localStorage.removeItem('car_wash_step_1');
    localStorage.removeItem('car_wash_step_2');
    localStorage.removeItem('car_wash_step_3');
  }, [courseItems, status, storeCustomerInfo]);

  useEffect(() => {
    if (step1Data.car.carwashItemCode) {
      const item = courseItems.find(
        (i) => i.code === step1Data.car.carwashItemCode,
      );
      setCourseItem(item);
    } else {
      setCourseItem(undefined);
    }
    if (step1Data.car.optionItemCodes) {
      const items = optionItems.filter((i) =>
        step1Data.car.optionItemCodes.includes(i.code),
      );
      setOptionItem(items);
    } else {
      setOptionItem([]);
    }
  }, [courseItems, optionItems, step1Data]);

  const submitStep1 = (data: Step1FormData) => {
    setStep(steps.REGISTATION);
    setStep1Data(data);
  };

  const submitStep2 = (data: Step2FormData) => {
    setStep(steps.ADDITIONAL_INFO);
    setStep2Data(data);
  };

  const submitStep3 = (data: Step3FormData) => {
    setStep(steps.REVIEW);
    setStep3Data(data);
  };

  const submitStep4 = async (data?: CreateCarWashParams) => {
    if (!data) return;
    const price =
      optionItem.reduce((current, item) => current + item.price, 0) +
      (courseItem?.price || 0);
    if (!prepaid?.balance_total || prepaid?.balance_total < price) {
      localStorage.setItem('step', JSON.stringify(step));
      localStorage.setItem('car_wash_step_1', JSON.stringify(step1Data));
      localStorage.setItem('car_wash_step_2', JSON.stringify(step2Data));
      localStorage.setItem('car_wash_step_3', JSON.stringify(step3Data));
      localStorage.setItem('source_page', paths.chargePrepaid);
      f7.view.main.router.navigate(paths.chargePrepaid, {
        props: {
          redirectPage: 'car_wash_conditioner',
          previousPage: 'car_wash_conditioner',
        },
      });
      return;
    }
    f7.preloader.show();
    try {
      if (!submitSuccess) {
        f7.preloader.show();
        const { data: rs } = await createCarWash(data);
        if (!rs.success) {
          f7.dialog.alert('', rs.error_message);
          f7.preloader.hide();
          return;
        }
        if (storeCustomerInfo.id) {
          const { data: basicInfo } = await customerInfoApi({
            m_customer_id: storeCustomerInfo.id,
          });
          await dispatchBasicInfo(basicInfo);
        }
        setSubmitSuccess(true);
      }
      f7.view.main.router.navigate(paths.carwashEditSuccess('create'));
    } catch {}
    f7.preloader.hide();
  };

  return {
    step,
    step1Data,
    step2Data,
    step3Data,
    courseItems,
    optionItems,
    courseItem,
    optionItem,
    submitSuccess,
    setStep,
    submitStep1,
    submitStep2,
    submitStep3,
    submitStep4,
  };
};
