import { Router } from 'framework7/types';
import { accountRoutes } from './accountRoutes';
import { carCommisionedWorkRoutes } from './carCommisionedWorkRoutes';
import { carInformationRoutes } from './carInformation';
import { carRepairRoutes } from './carRepairRoutes';
import { connectorRoutes } from './connectorRoutes';
import { contractRoutes } from './contractRoutes';
import { couponRoutes } from './couponRoutes';
import { drivingRoutes } from './drivingReportRoutes';
import { helpRoutes } from './helpRoutes';
import { insuranceRoutes } from './insuranceRoutes';
import { loginRoutes } from './loginRoutes';
import { noticeRoutes } from './noticeRoutes';
import { otherRoutes } from './otherRoutes';
import { paymentRoutes } from './paymentRoutes';
import { prepaidRoutes } from './prepaidRoutes';
import { rankRoutes } from './rankRoutes';
import { roadServiceRoutes } from './roadServiceRoutes';
import { selfScanRoutes } from './selfScanRoutes';
import { settingRoutes } from './settingRoutes';
import { warrantyManagementRoutes } from './warrantyManagementRoutes';

export const routes: Router.RouteParameters[] = [
  ...otherRoutes,
  ...loginRoutes,
  ...carCommisionedWorkRoutes,
  ...carInformationRoutes,
  ...warrantyManagementRoutes,
  ...prepaidRoutes,
  ...couponRoutes,
  ...noticeRoutes,
  ...roadServiceRoutes,
  ...accountRoutes,
  ...paymentRoutes,
  ...settingRoutes,
  ...helpRoutes,
  ...selfScanRoutes,
  ...drivingRoutes,
  ...connectorRoutes,
  ...insuranceRoutes,
  ...contractRoutes,
  ...carRepairRoutes,
  ...rankRoutes,
];
