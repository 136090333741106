import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './WarrantyCancelStyle.module.css';
import { CustomList } from '@/components/uiParts/List/CustomList';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';

interface WarrantyCancelProps {
  handleClickLink: () => void;
  isCancelable: boolean;
  isUpdatable: boolean;
  cancelableTerm: string;
  f7router: Router.Router;
}

const subTextStyle = { color: '#a5a5a5', marginTop: '4px' };
const cancelableSubTextStyle = {
  color: '#ff5d6a',
  fontWeight: 'bold',
  marginTop: '4px',
};
export const WarrantyCancel: FC<WarrantyCancelProps> = ({
  handleClickLink,
  isCancelable,
  isUpdatable,
  cancelableTerm,
  f7router,
}) => {
  const textColor = isCancelable ? '#ff5d6a' : '#323232';
  const subText = isCancelable
    ? `※${cancelableTerm} 23:59まで解約可能です`
    : '※現在解約可能期間外です';
  const subTextStyleObj = isCancelable ? cancelableSubTextStyle : subTextStyle;
  return (
    <RoundSheet py="0px">
      {isUpdatable ? (
        <div onClick={handleClickLink}>
          <CustomList
            path=""
            text="保証を解約する"
            textColor={textColor}
            subText={subText}
            subTextStyle={subTextStyleObj}
            isBold
            isTopBorder={false}
            f7router={f7router}
          />
        </div>
      ) : (
        <div className={style.nothing}>更新可能な保証はありません</div>
      )}
    </RoundSheet>
  );
};
